.compareTable {
    &__row {
        width: 1170px;
        display: flex;
        overflow: hidden;
        position: relative;
        background: #f7f8fa;
        margin-bottom: 8px;
        //border-radius: 8px;
    }

    &__head {
        width: 240px;
        flex-basis: 240px;
        flex-shrink: 0;
        font-weight: 700;
        padding: 15px;
    }

    &__body {
        position: relative;
        top: 0;
        left: 0;
        overflow-x: hidden;
        display: flex;
    }

    &__data {
        transition: left ease-in-out 0.5s;
        left: 0;
        border-left: 2px solid #fff;
        position: relative;
        width: 328px;
        flex-basis: 328px;
        flex-shrink: 0;
        padding: 15px;
        margin-right: 15px;
    }

    &__prop {
        display: none;
    }
}

@media (max-width: 1200px) {
    .compareTable {
        &__row {
            width: 960px;
        }
    }
}

@media (max-width: 980px) {
    .compareTable {
        &__row {
            width: 720px;
        }

        &__head {
           display: none;
        }

        &__prop {
            display: block;
            font-weight: 700;
        }
    }
}
