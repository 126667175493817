.feedback {
  background: linear-gradient(84.03deg, #2E58C1 29.86%, #4E2EC1 101.28%);
  padding: 80px 0 100px 70px;
  color: #fff;
  overflow: hidden;

  .container {
    position: relative;
  }

  @media(max-width: 1400px) {
    padding: 80px 0 70px;
  }

  @media(max-width: 768px) {
    padding: 50px 0 60px;
  }

  &__title {
    position: relative;
    font-weight: bold;
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 26px;
    z-index: 2;

    @media(max-width: 1400px) {
      max-width: 60%;
    }

    @media(max-width:768px) {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &__desc {
    position: relative;
    z-index: 2;
    font-size: 16px;
    line-height: 190%;
    max-width: 55%;
    margin-bottom: 40px;

    @media(max-width: 1400px) {
      max-width: 60%;
    }

    @media(max-width:768px) {
      max-width: 75%;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 190%;
    }
  }

  &__button {
    position: relative;
    display: inline-block;

    .button {
      @media(max-width: 768px) {
        height: 50px;
      }
    }

    &:after {
      position: absolute;
      content: "";
      top: 20px;
      right: -170px;
      width: 140px;
      height: 160px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 142 144'%3e%3cpath fill='%23fff' d='M.943 4.267a.5.5 0 010-.707L4.125.378a.5.5 0 01.707.707L2.004 3.913l2.828 2.829a.5.5 0 01-.707.707L.943 4.267zM141 144c0-47.434-22.427-91.47-46.29-107.761C70.81 19.923 31.38 4.413 1.297 4.413v-1c30.146 0 69.825 15.512 93.976 32C119.461 51.926 142 96.305 142 144h-1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;

      @media(max-width: 768px) {
        right: -130px;
        background-size: 150%;
        background-position: top left;
        width: 120px;
      }

      @media(max-width: 440px) {
        right: -65px;
        background-size: 150%;
        background-position: top left;
        width: 60px;
      }

      @media(max-width: 360px) {
        background-image: none;
        display: none;
      }

    }
  }

  &__picture {
    position: absolute;
    max-width: 580px;
    height: 490px;
    top: -70px;
    right: -100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media(max-width: 768px) {
        min-width: 280px;
      }
    }

    @media(max-width: 1600px) {
      right: 15px;
    }

    @media(max-width: 1400px) {
      max-width: 320px;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      width: 40%;
    }

    @media(max-width: 768px) {
      width: 170px;
      right: 0;
      top: 30%;
      overflow-x: hidden;
    }

  }
}
