.nav__list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
    position: relative;
}

.nav__item {
	&:hover {
		.nav__link {
			background-color: $bgGray;
		}

		.nav__sublist {
			opacity: 1;
			transform: rotateX(0deg);
			visibility: visible;
			z-index: 4;
		}
	}
}

.nav__link {
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;


	font-size: 14px;
	line-height: 17px;
	color: $dark;
	text-decoration: none;
	padding: 0 15px;
	transition: background-color $transitionTime ease-in-out;
}

.nav__sublist {
	list-style: none;
	padding: 48px;
	margin: 0;

	position: absolute;
	top: 100%;
    left:0;
	z-index: 1;
	width: 1170px;;

	background: $bgGray;
	box-shadow: 0px 18px 20px rgba(0, 0, 0, 0.1);
	border-radius: 0px 0px 6px 6px;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;

	opacity: 0;
	transform: rotateX(90deg);
	transition: all 0.4s ease 0s;
	visibility: hidden;
	transform-origin: top center;
}

.nav__column {
	width: calc(25% - 23px);
	margin-right: 30px;
	z-index: 2;

	&:last-of-type {
		margin-right: 0;
	}
}

.nav__spec {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color:$dark;
	text-decoration: none;

	&:hover {
		box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

		.nav__spec-image {
			filter: grayscale(0.5);
		}
	}
}

.nav__spec-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: #fff;
	width: 100%;
	padding: 17px;
}

.nav__spec-image {
	width: 100%;
}

.nav__spec-title {
	font-size: 16px;
	font-weight: 700;
	line-height: 30px;
	text-align: center;
}

.nav__spec-text {
	font-size: 12px;
	line-height: 23px;
	text-align: center;
}

.nav__column-item {
	margin-bottom: 34px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.nav__column-item-header {
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	color: $dark;
	margin-bottom: 13px;
}

.nav__column-item-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.nav__column-item-list-item-link {
	font-size: 12px;
	line-height: 28px;
	text-decoration: none;
	color: $dark;

	&:hover {
		text-decoration: underline;
		color: $blue;
	}
}


@media (max-width: 1200px) {
	.nav__list {
		display: none;
	}
}
