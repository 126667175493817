.search {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 241px;
}

.search__input {
	border: 1px solid #ECEEF3;
	background: #FAFBFF;
	box-sizing: border-box;
	border-radius: 3px;
	height: 40px;
	width: 201px;
	padding: 0 20px;

	font-size:11px;

	&:focus {
		border: 1px solid $borderColor;
	}

}

.search__button {
	background: $blue;
	border-radius: 2px;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}


@media (max-width: 1200px) {
	.search {
		width: 100%;
		position: relative;
	}

	.search__input {
		width: 100%;
		background: $bgGray;
	}

	.search__button {
		background-color: transparent;
		position: absolute;
		top:0;
		right:0;

		svg path {
			fill: $dark;
		}
	}
}
