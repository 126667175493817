.opportunities {
    &__title {
        margin-bottom: 68px;

        @media(max-width: 768px) {
            margin-bottom: 34px;
            text-align: center;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        @media(max-width: 768px) {
            flex-direction: column;
        }
    }

    &__item {
        margin: 15px;
        width: calc(33.33% - 25px);
        background: #f7f8fa;
        height: auto;
        border-radius: 8px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0;
        }

        @media(max-width: 768px) {
            margin: 0 0 20px 0;
            width: 100%;
        }
    }

    &__bg {
        height: 258px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
        }
    }

    &__info {
        position: relative;
        padding: 40px 30px;

        @media(max-width: 1200px) {
            padding: 22px 24px;
        }

        @media(max-width: 980px) {
            padding: 22px 24px;
        }

        @media(max-width: 768px) {
            padding: 22px 24px;
            font-size: 14px;
        }
    }

    &__ico {
        position: absolute;
        top: 48px;
        left: 40px;

        @media(max-width: 1200px) {
            left: 30px;
            top: 30px;

            img {
                width: 34px;
                height: 34px;
            }
        }

        @media(max-width: 980px) {
            left: 20px;
            top: 26px;
        }
    }

    &__sub-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 14px;

        @media(max-width: 980px) {
            font-size: 14px;
        }

        @media(max-width: 768px) {
            margin-bottom: 4px;
        }
    }

    &__desc {
        font-size: 14px;
        line-height: 150%;

        @media(max-width: 980px) {
            font-size: 12px;
        }
    }
}
