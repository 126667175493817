.rating {
	display: flex;
}

.rating__stars {
	display: flex;
}

.rating__star {
	margin-right: 2px;

	&:last-of-type {
		margin-right: 0;
	}

	svg path {
		fill: #EBECF2;
	}

	&--active {
		svg path {
			fill: #FCE885;
		}
	}
}

.rating__review-count {
	font-size: 12px;
	line-height: 15px;
	color: $dark;
	text-decoration: none;
	border-bottom: 1px dashed $dark;

	&:hover,
	&:focus {
		border-bottom-color: transparent;
	}
}


@media (max-width: 480px) {
	.rating__stars {
		margin-right: 10px;
	}

	.rating__star {
		svg {
			width: 11px;
			height:11px;
		}
	}

	.rating__review-count {
		font-size: 10px;
	}
}
