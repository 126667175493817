.descriptor {
    position: absolute;
    top: 14px;
    left: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    color: #fff;

    P {
        margin: 0 0 6px 0;
        display: inline-block;
        border-radius: 30px;
        padding: 4px 10px;
    }

    &__new {
        background-color: #92CCDB;
    }

    &__discount {
        background-color: #C12E33;
    }

    &__best-choice {
        background-color: #92A7DB;
    }

}

.favorite-btns,
.actionButtons {
    position: absolute;
    display: none;
    top: 14px;
    right: 14px;
    width: 18px;
    z-index: 110;

    &__btn {
        position: relative;
        display: block;
        padding: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        width: 18px;
        height: 18px;
        margin-bottom: 16px;

        svg, i {
            width: 100%;
            height: 100%;
            fill: #CDD1DC;
            color: #cdd1dc;
            transition: 0.3s;
        }

        &:hover svg, &:hover i {

            cursor: pointer;
            fill: #C12E33;
            color: #C12E33;
        }

        &--active {
            svg, i {
                fill: #C12E33;
                color: #C12E33;
            }
        }
    }

    &__favorites {
        &:hover svg,
        &:hover i {
            cursor: pointer;
            fill: #C12E33;
            color: #C12E33;
        }

        &--active {
            svg, i {
                fill: #C12E33;
                color: #C12E33;
            }
        }
    }

    &__compare {
        &:hover svg,
        &:hover i {
            cursor: pointer;
            fill: $blue;
            color: $blue;
        }

        &--active {
            svg, i {
                fill: $blue;
                color: $blue;
            }
        }
    }
}

.cart-btn {
    position: absolute;
    display: none;
    top: 204px;
    right: 14px;
    width: 40px;
    height: 40px;
    padding: 8px;
    z-index: 110;
    border-radius: 6px;
    background-color: #2E58C1;
    transition: 0.3s;

    &:hover {
        background-color: #4571e0;
        box-shadow: 0px 10px 10px rgba(7, 14, 32, 0.05);
    }

    &:active {
        background-color: #1a42a7;
        box-shadow: 0px 10px 10px rgba(7, 14, 32, 0.05);
    }

    button {
        position: relative;
        display: block;
        cursor: pointer;
        padding: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        width: 100%;
        height: 100%;

        i {
            color: #fff;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.product-status {
    p {
        margin-top: 12px;
        font-size: 12px;
        line-height: 150%;
        position: relative;
        margin-left: 16px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: -16px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
    }

    &__availability::before {
        background-color: #3E8725;
    }

    &__close::before {
        background-color: #F3A845;
    }

    &__off::before {
        background-color: #C12E33;
    }
}

@media (max-width: 1200px) {
    .favorite-btns,
    .actionButtons,
    .cart-btn {
        display: block;
    }
}

@media (max-width: 728px) {
    .descriptor {
        font-size: 8px;
    }

    .cart-btn {
        width: 32px;
        height: 32px;
        padding: 6px 5px 8px;
        top: 72%;
    }

    .favorite-btns,
    .actionButtons {

        &__btn {
            width: 16px;
            height: 16px;
        }
    }
}
