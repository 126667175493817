.miniCart {
	background-color: #fff;
	position: fixed;
    z-index: 11;

	top: 0;
	right: -100%;
	transition: right $transitionTime ease-in-out;

	width: 645px;
    height:100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 35px 40px;

	&--open {
		right: 0;
	}

    &::-webkit-scrollbar {
        display: none;
    }
}

.miniCart__close {
	width: 40px;
	height: 40px;
	background: $gray;
	border-radius: 0px 0px 3px 0px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 0;
}

.miniCart__header {
	border-bottom: 1px solid #EBEDF2;
	padding: 19px 0;

	p {
		margin: 0;
		font-size: 20px;
		font-weight: 600;
		line-height: 38px;
	}
}

.miniCart__row {
	display: flex;
	border-bottom: 1px solid #EBEDF2;
	padding: 20px 0;
	align-items: flex-start;
	justify-content: space-between;
}

.miniCart__row-image {
	width: 106px;
}

.miniCart__row-content {
	width: 265px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.miniCart__row-name {
	color: $dark;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.miniCart__row-total {
	font-size: 11px;
	line-height: 17px;
	color: $superGray;
	margin-top: 9px;
	margin-bottom: 9px;
}

.miniCart__row-cost {
	font-weight: 600;
}

.miniCart__row-remove {
	font-size: 11px;
	line-height: 17px;
	color: $superGray;
	text-decoration: none;

	svg {
		margin-right: 9px;
	}

	&:focus,
	&:hover {
		color: $red;

		svg {
			path {
				fill: $red;
			}
		}
	}
}

.miniCart__total {
	background: $bgGray;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 59px;
	margin-top: 20px;
	padding: 0 30px;
	font-size: 24px;
	line-height: 36px;
}

.miniCart__total-title {
	color: $gray;
}

.miniCart__total-value {
	font-weight: 600;
}

.miniCart__footer {
	margin-top: 30px;
}

.miniCart__button {
	width: 100%;
	margin-bottom: 10px;
}

.miniCart__free-delivery {
	margin-top: 24px;

	strong {
		font-weight: 600;
	}
}

.miniCart__empty {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
	.miniCart {
		width: 100%;
		padding: 15px 10px;
		height: 100%;
	}

	.miniCart__row-content {
		width: 120px;
	}

	.miniCart__header {
		padding-top: 0;
		margin-top: -10px;
	}

	.miniCart__free-delivery {
		font-size: 12px;
	}
}
