.sidenav {
	background-color: #fff;
	position: fixed;
	z-index:4;
	display:none;

	left: -100%;
	transition: left $transitionTime ease-in-out;

	&--open {
		left: 0;
	}
}

.sidenav__close {
	width: 40px;
	height: 40px;
	background: $gray;
	border-radius: 0 0 3px 0;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	left: 100%;
	top: 0;
}

.sidenav__content {
    width: calc(100vw - 40px);
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 100vh;
	padding: 10px 0 100px 0;
}

.sidenav__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sidenav__location {
	position: relative;
	height: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	img {
		margin-right: 5px;
	}
}

.sidenav__location__selected {
	font-family: $font;
	font-size: 14px;
	font-weight: 400;
	line-height: 10px;
	text-align: right;
}

.sidenav__logo {
	display: block;
	width: 115px;
}

.sidenav__location {
	margin: 0 30px;
    color:$dark;
}

.sidenav__catalog {
	margin: 20px 0;

    &.mobileCatalog {
        display: none;
    }
}

.sidenav__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.sidenav__item {
	margin-bottom: 10px;
    background-color: $bgGray;


	&:last-of-type {
		margin-bottom: 0;
	}

	.sidenav__link {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
        padding: 10px;

		background-position: right center;
		background-repeat: no-repeat;
		background-size: auto;

		font-family: $font;
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		color: $dark;
		text-decoration: none;

		transition: background-color $transitionTime ease-in-out;

		span {
			width: 70%;
		}

		&:hover {
			background-color: darken($bgGray, 5%);
		}
	}
}

.sidenav__list-2 {
    padding: 20px 0 20px 20px;
    display: none;

    &.is-opened {
        display: block;
    }
}

.sidenav__item-2 {
    margin-bottom: 20px;

    &-title {
        font-weight: 700;
    }

    a {
        font-family: $font;
        font-size: 14px;
        line-height: 18px;
        color: $dark;
        text-decoration: none;

        transition: background-color $transitionTime ease-in-out;
        margin: 10px 0;
        display: block;

        span {
            width: 70%;
        }

        &:hover {
            background-color: darken($bgGray, 5%);
        }
    }
}

.sidenav__list-3 {
    padding-left: 20px;
}

.sidenav__pricelist {
	@extend .header__pricelist;
	width: 100%;
}

.sidenav__login {
	height: 60px;
	display: flex;
	align-items: center;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	margin-top: 20px;

	font-size: 14px;
	line-height: 13px;
	color: $dark;
	text-decoration: none;

	img {
		margin-right: 8px;
		margin-left: 12px;
	}

	&:hover {
		background-color: $bgGray;
	}
}

.sidenav__usernav {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .usernav__list--open {
        display: block;
        position: static;
        background: #fff;
        box-shadow: none;
        padding: 0;
    }

    .usernav__item {
        padding: 15px;
    }
}

.sidenav__phone {
	width: 100%;
	flex-direction: column;
	align-items: flex-start;

	.phones__list {
		padding:0 10px;
		display: none;
	}


	.phones__list--open {
		display: block;
	}

	.phones__item {
		padding:10px 0;
	}
}

.sidenav__socials {
	height: 60px;
	display: flex;
	align-items: center;
	background-color: $bgGray;
}


.sidenav__pages {
	padding: 0;
	list-style: none;
	margin: 0;
}

.sidenav__page {
	&:hover {
		background-color: $bgGray;
	}

	a {
		height: 40px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $borderColor;

		font-size: 14px;
		line-height: 40px;
		text-decoration: none;
		color: $dark;

		margin-left:20px;

	}
}


@media (max-width:1200px) {
	.sidenav {
		display:block;
	}
}


@media (max-width: 768px) {
    .sidenav__catalog {
        &.mobileCatalog {
            display: block;
        }
    }
}
