.overlay {
	position: fixed;
	top:0;
	left:0;

	width: 100vw;
	height: 100vh;
	transition: background-color, backdrop-filter $transitionTime ease-in-out;
	z-index:-1;
	visibility: hidden;

	&.overlay--show {
		z-index:3;
		background-color: rgba(26, 26, 26, 0.2);
		backdrop-filter: blur(1px);
		visibility: visible;
	}
}
