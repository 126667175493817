.tree {
    &__title {
        font-weight: 700;
        font-size: 18px;
    }

    &__list {
        margin: 0;
        padding: 20px 0;
    }
}

.tree-level1 {
    margin-bottom: 10px;

    &__list {
        display: none;
    }

    &__info {
        display: flex;
        justify-content: space-between;

        padding: 6px 8px;
        border-radius: 6px;
        font-size: 12px;
        line-height: 130%;
        font-weight: 600;

        &:hover {
            .tree-level1__action {
                &:after {
                    display: none;
                }

                i {
                    opacity: 1;
                }
            }

            & {
                background: $bgColor;
            }
        }
    }

    &--active {
        .tree-level1__info {
            color: #fff;
            background: $dark;

            &:hover {
                & {
                    color: #fff;
                    background: $dark;
                }

                .tree-level1__action {
                    transform: rotate(180deg)
                }
            }
        }

        .tree-level1__link {
            color: #fff;
        }
    }

    &--open {
        .tree-level1__list {
            display: block;
        }
    }

    &--has_active_child {
        .tree-level1__info {
            .tree-level1__action {
                position: relative;
                transform: rotate(180deg);

                &:after {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background: $dark;
                    border-radius: 50%;
                    top: 5px;
                    right: 2px;
                }
            }
        }
    }

    &__link {
        color: $dark;
        text-decoration: none;
        text-transform: uppercase;
    }

    &__count {
        color: #888;
        font-size: 9px;
        margin-left: 10px;
        font-weight: 500;
        width: 20px;
        text-align: center;
    }

    &__action {
        cursor: pointer;

        i {
            opacity: 0;
        }
    }
}

.tree-level2 {
    &__list {
        display: none;
    }

    &__info {
        display: flex;
        justify-content: space-between;

        padding: 6px 8px;
        border-radius: 6px;
        font-size: 12px;
        line-height: 130%;
        font-weight: 400;

        &:hover {
            & {
                background: $bgColor;
            }

            .tree-level2__action {
                opacity: 1;
            }
        }
    }

    &--active {
        .tree-level2__info {
            color: #fff;
            background: $dark;

            &:hover {
                .tree-level2__action {
                    transform: rotate(180deg)
                }
            }

            .tree-level2__link {
                color: #fff;
            }
        }

        &:hover {
            .tree-level2__info {
                color: #fff;
                background: $dark;
            }
        }
    }

    &--open {
        .tree-level2__list {
            display: block;
            margin-left: 10px;
        }

        .tree-level2__action {
            opacity: 1;
            transform: rotate(180deg)
        }
    }

    &__link {
        color: $dark;
        text-decoration: none;
    }

    &__count {
        color: #888;
        font-size: 9px;
        margin-left: 10px;
        font-weight: 500;
        width: 20px;
        text-align: center;
    }

    &__action {
        opacity: 0;
        cursor: pointer;
    }
}

.tree-level3 {
    &__info {
        display: flex;
        justify-content: space-between;

        padding: 6px 8px;
        border-radius: 6px;
        font-size: 12px;
        line-height: 130%;
        font-weight: 400;

        &:hover {
            & {
                background: $bgColor;
            }
        }
    }

    &--active {
        .tree-level3__info {
            color: #fff;
            background: $dark;

            .tree-level3__link {
                color: #fff;
            }
        }

        &:hover {
            .tree-level3__info {
                color: #fff;
                background: $dark;
            }
        }
    }


    &__link {
        color: $dark;
        text-decoration: none;
    }

    &__count {
        color: #888;
        font-size: 9px;
        margin-left: 10px;
        font-weight: 500;
        width: 20px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .tree {
        display: none;
    }
}
