$utp: ".utp";

#{$utp} {
    position: relative;
    @include indexBtn();

    color: #fff;

    .container {
        position: relative;

        @media (min-width: 1200px) and (max-width: 1980px) {
            max-width: 100%;
        }
    }

    &__arrows {
        left: 50%;
        width: 1360px;
        transform: translate(-50%, -50%);
    }

    @media (min-width: 1200px) and (max-width: 1980px) {
        #{$utp}__slide {
            transition: opacity 0.2s;
            width: 1170px;
            height: 620px;
            overflow: hidden;
        }

        .swiper-slide-next, .swiper-slide-prev {
            opacity: 0.5;
        }
    }

    @media (min-width: 981px) and (max-width: 1199px) {
        #{$utp}__slide {
            width: 960px;
            height: 624px;
            overflow: hidden;
        }
    }

    @media (min-width: 620px) {
        #{$utp}__slide {
            height: 624px;
        }
    }

    @media (max-width: 980px) {
        #{$utp}__slide {
            width: 100%;
            height: 678px;
            overflow: hidden;
        }
    }

    @media (max-width: 480px) {
        #{$utp}__slide {
            height: 376px;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 1170px;
        height: 100%;

        @media (max-width: 980px) {
            width: 100%;
        }

        @media (max-width: 640px) {
            width: 640px;
        }

        @media (max-width: 480px) {
            height: 376px;
            width: 100%;
        }
    }

    &__image {
        width: 1170px;
        max-width: none;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        max-height: 100%;

        @media (max-width: 980px) {
            width: 100%;
        }
    }

    #{$utp}__wrap-text {
        position: relative;
        z-index: 2;
    }

    #{$utp}__btn-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    #{$utp}__button {
        position: relative;
        display: inline-block;
        margin-right: 10px;
    }

    &__template1 {
        #{$utp}__wrap-text {
            width: 50%;
            padding: 5% 0 5% 5%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        #{$utp}__title {
            font-weight: bold;
            font-size: 40px;
            line-height: 1.2;
            margin-bottom: 20px;
        }

        #{$utp}__text {
            font-size: 30px;
        }

        #{$utp}__btn-wrap {
            margin-top: 55px;
        }

        #{$utp}__button_1 {
            background-color: #FCE885;
            color: $dark;
            padding: 20px 30px;
            text-transform: uppercase;
            font-size: 18px;
            border: 1px solid #FCE885;
            margin-right: 10px;

            &:hover {
                background-color: transparent;

                color: #FCE885;
            }
        }

        #{$utp}__button_2 {
            background-color: transparent;
            border: 1px solid #FCE885;
            color: #FCE885;
            font-size: 16px;
            padding: 20px 30px;
            text-transform: uppercase;

            &:hover {
                background-color: #FCE885;
                color: $dark;
            }
        }

        @media (max-width: 1200px) {

            #{$utp}__title {
                font-size: 34px;
            }

            #{$utp}__text {
                font-size: 28px;
            }

            #{$utp}__btn-wrap {
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                max-width: 300px;
            }

            #{$utp}__button {
                text-align: center;
            }

            #{$utp}__button_2 {
                margin-top: 15px;
            }
        }

        @media (max-width: 980px) {
            #{$utp}__wrap-text {
                width: 100%;
                padding: 5%;
            }

            #{$utp}__title {
                font-size: 40px;
            }

            #{$utp}__text {
                font-size: 30px;
            }

            #{$utp}__btn-wrap {
                margin-top: 130px;
            }
        }

        @media (max-width: 768px) {
            #{$utp}__button_1 {
                padding: 20px 25px;
                font-size: 14px;
            }
        }

        @media (max-width: 480px) {
            #{$utp}__title {
                font-size: 24px;
            }

            #{$utp}__text {
                font-size: 18px;
            }

            #{$utp}__btn-wrap {
                max-width: 100%;
                margin-top: 30px;
            }
        }
    }

    &__template2 {
        #{$utp}__wrap-text {
            padding: 68px 29% 96px 70px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        #{$utp}__title {
            font-weight: bold;
            font-size: 65px;
            line-height: 1.2;
        }

        #{$utp}__text {
            font-size: 30px;
            line-height: 1.2;
            max-width: 80%;
        }

        #{$utp}__button_1 {
            background-color: #FCE885;
            color: $dark;
            padding: 20px 30px;
            text-transform: uppercase;
            font-size: 18px;
            border: 1px solid #FCE885;

            &:hover {
                background-color: transparent;

                color: #FCE885;
            }
        }

        #{$utp}__button_2 {
            background-color: transparent;
            border: 1px solid #FCE885;
            color: #FCE885;
            font-size: 16px;
            padding: 20px 30px;
            text-transform: uppercase;

            &:hover {
                background-color: #FCE885;
                color: $dark;
            }
        }

        #{$utp}__price {
            font-size: 52px;
            font-weight: 700;
            margin-left: 20px;
        }

        @media (max-width: 1200px) {
            #{$utp}__wrap-text {
                padding: 92px 29% 96px 70px;
                justify-content: flex-start;
            }

            #{$utp}__title {
                font-size: 50px;
                margin-bottom: 50px;
            }

            #{$utp}__text {
                font-size: 24px;
                margin-bottom: 50px;
            }

            #{$utp}__btn-wrap {
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                max-width: 55%;
                margin-top: 0;
            }

            #{$utp}__button {
                text-align: center;
                padding: 30px 30px;
                margin-bottom: 15px;
            }

            #{$utp}__button_2 {
                margin-top: 15px;
            }

            #{$utp}__price {
                margin-left: 0;
                margin-top: 50px;
            }
        }

        @media (max-width: 980px) {
            #{$utp}__wrap-text {
                padding: 30px 15px;
            }

            #{$utp}__title {
                font-size: 40px;
            }

            #{$utp}__text {
                font-size: 30px;
            }
        }

        @media (max-width: 768px) {
            #{$utp}__text {
                font-size: 28px;
                max-width: 100%;
            }

            #{$utp}__btn-wrap {
                max-width: 50%
            }

            #{$utp}__button {
                padding: 20px 25px;
                font-size: 14px;
            }
        }

        @media (max-width: 480px) {
            #{$utp}__title {
                font-size: 28px;
            }

            #{$utp}__text {
                font-size: 18px;
            }

            #{$utp}__btn-wrap {
                max-width: 100%;
                margin-top: 30px;
            }
        }
    }

    &__template3 {
        #{$utp}__wrap-text {
            padding: 54px 29% 96px 65px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        #{$utp}__title {
            font-weight: 700;
            font-size: 40px;
            text-align: center;
            line-height: 1.2;
            height: 146px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #{$utp}__text {
            font-size: 30px;
            line-height: 1.2;
            max-width: 80%;
            color: #000;

            ul {
                margin-bottom: 20px;
                margin-top: 50px;

                li {
                    position: relative;
                    margin-bottom: 10px;
                    padding-left: 25px;
                }
                li::before {
                    content: "• ";
                    color: purple;
                    font-size: 75px;
                    height: 100%;
                    position: absolute;
                    left:0;
                    top: -5px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        #{$utp}__button_1 {
            background-color: #FCE885;
            color: $dark;
            padding: 20px 30px;
            text-transform: uppercase;
            font-size: 18px;
            border: 1px solid #FCE885;

            &:hover {
                background-color: transparent;
                color: #FCE885;
            }
        }

        @media (max-width: 1200px) {
            #{$utp}__wrap-text {
                padding: 92px 29% 96px 70px;
                justify-content: flex-start;
            }

            #{$utp}__title {
                font-size: 38px;
                margin-bottom: 50px;
            }

            #{$utp}__text {
                font-size: 24px;
                margin-bottom: 50px;
            }

            #{$utp}__btn-wrap {
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                max-width: 55%;
                margin-top: 0;
            }

            #{$utp}__button {
                text-align: center;
                padding: 30px 30px;
                margin-bottom: 15px;
            }

            #{$utp}__button_2 {
                margin-top: 15px;
            }

            #{$utp}__price {
                margin-left: 0;
                margin-top: 50px;
            }
        }

        @media (max-width: 980px) {
            #{$utp}__wrap-text {
                padding: 71px 4%;
            }

            #{$utp}__title {
                font-size: 30px;
                height: 108px;
            }

            #{$utp}__text {
                font-size: 30px;
            }
        }

        @media (max-width: 768px) {
            #{$utp}__title {
                font-size: 24px;
            }

            #{$utp}__text {
                font-size: 28px;
                max-width: 100%;
            }

            #{$utp}__btn-wrap {
                max-width: 50%
            }

            #{$utp}__button {
                padding: 20px 25px;
                font-size: 14px;
            }
        }

        @media (max-width: 480px) {
            #{$utp}__title {
                font-size: 18px;
            }

            #{$utp}__text {
                font-size: 16px;
            }

            #{$utp}__btn-wrap {
                max-width: 100%;
                margin-top: 30px;
            }
        }
    }

    &__template4 {
        #{$utp}__wrap-text {
            padding: 84px 42% 0 70px;
        }

        #{$utp}__title {
            font-weight: bold;
            font-size: 50px;
            line-height: 1.2;
        }

        #{$utp}__subtitle {
            font-weight: bold;
            font-size: 40px;
            line-height: 1.2;
        }

        #{$utp}__text {
            font-size: 30px;
            margin-top: 90px;
        }

        #{$utp}__btn-wrap {
            margin-top: 90px;
        }

        #{$utp}__button_1 {
            background-color: #FCE885;
            color: $dark;
            padding: 20px 30px;
            text-transform: uppercase;
            font-size: 18px;
            border: 1px solid #FCE885;
            margin-right: 10px;

            &:hover {
                background-color: transparent;

                color: #FCE885;
            }
        }

        #{$utp}__button_2 {
            background-color: transparent;
            border: 1px solid #FCE885;
            color: #FCE885;
            font-size: 16px;
            padding: 20px 30px;
            text-transform: uppercase;

            &:hover {
                background-color: #FCE885;
                color: $dark;
            }
        }

        @media (max-width: 1200px) {
            #{$utp}__wrap-text {
                padding: 150px 50% 130px 70px;
            }

            #{$utp}__title {
                font-size: 30px;
            }

            #{$utp}__text {
                font-size: 24px;
            }

            #{$utp}__btn-wrap {
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                max-width: 300px;
            }

            #{$utp}__button {
                text-align: center;
            }

            #{$utp}__button_2 {
                margin-top: 15px;
            }
        }

        @media (max-width: 980px) {
            #{$utp}__wrap-text {
                padding: 30px 15px;
            }

            #{$utp}__title {
                font-size: 40px;
            }

            #{$utp}__title {
                font-size: 36px;
            }

            #{$utp}__text {
                font-size: 30px;
                margin-top: 40px;
            }

            #{$utp}__btn-wrap {
                margin-top: 40px;
            }

            #{$utp}__btn-wrap {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                max-width: 100%;
            }

            .utp__button_2 {
                margin-top: 0;
            }
        }

        @media (max-width: 480px) {
            #{$utp}__btn-wrap {
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                max-width: 300px;
            }

            #{$utp}__button_2 {
                margin-top: 15px;
            }

            #{$utp}__title {
                font-size: 30px;
            }

            #{$utp}__subtitle {
                font-size: 24px;
            }

            #{$utp}__text {
                font-size: 18px;
            }

            #{$utp}__btn-wrap {
                max-width: 100%;
                margin-top: 30px;
            }
        }
    }

    &__template5 {
        .utp__image-link {
            display: flex;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 2;
        }
    }
}
