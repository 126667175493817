.cart-products {
    width: 770px;

    &__item {
        display: flex;
        border-bottom: 1px solid #EBEDF2;
        padding-bottom: 40px;
        transition: 0.3s;
        margin-bottom: 46px;

        &:hover {
            border-bottom: 1px solid #aa98e7;
        }
    }

    &__img {
        position: relative;
        width: 106px;
        height: 108px;
        background-color: #EDEDED;
        border-radius: 2px;
        margin-right: 29px;

        a {
            display: flex;
            width: 100%;
            height: 100%;
        }

        img {
            max-width: 100%;
        }
    }

    &__wrap-elems {
        display: flex;
        width: calc(100% - 135px);
        align-items: flex-start;
        position: relative;
        padding-bottom: 18px;
    }

    &__elems-control {
        display: flex;
        position: relative;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 24px;
    }

    &__title {
        font-size: 14px;
        line-height: 150%;
        color: $dark;

        &:hover {
            color: $purple;
        }
    }

    &__wrap-price {
        line-height: 150%;
        text-align: right;
        min-width: 140px;
    }

    &__total-price {
        font-weight: 600;
        font-size: 14px;
    }

    &__price-product {
        font-size: 11px;
    }

    &__form-count {
        display: flex;
    }

    &__btn-count {
        font-size: 22px;
        padding: 6px 10px;

        &:hover,
        &:active,
        &:focus {
            font-weight: 700;
        }
    }

    &__edit-count {
        height: 40px;
        border: 1px solid #EBECF2;
        border-radius: 3px;
        width: 60px;
        text-align: center;
        font-size: 14px;
        line-height: 150%;
        transition: 0.25s;

        &:hover {
            border: 1px solid $purple;
        }
    }

    &__wrap-elem-control {
        position: absolute;
        bottom: 0px;
    }

    &__elem-control-btn {
        display: inline-block;
        margin-right: 24px;

        svg {
            transition-property: fill;
            transition-duration: 250ms;
            height: 14px;
            width: 14px;
            fill: #DCDFE5;
        }

        &.cart-products__favorite-btn--active {
            svg {
                fill: #D41A1A;
            }
        }

        span {
            margin-left: 4px;
            font-size: 11px;
            line-height: 150%;
        }

        &:hover {
            color: #961010;

            svg {
                fill: #D41A1A;
            }
        }
    }

    &__del-btn {
        &:hover {
            fill: #D41A1A;
        }
    }

    &__bonus-info {
        position: absolute;
        bottom: 0;
        right: 0;
        font-weight: 500;
        font-size: 11px;
        line-height: 150%;

        span {
            font-weight: 600;
            margin-right: 5px;
        }
    }
}

@media (max-width: 728px) {
    .cart-products {
        width: 100%;

        &__elems-control, &__wrap-elems {
            flex-direction: column;
        }

        &__wrap-elems {
            width: calc(100% - 80px);
        }

        &__elems-control {
            padding-right: 32px;
            padding-bottom: 20px;
        }

        &__wrap-elem-control {
            top: 0;
            right: 0;
        }

        &__elem-control-btn {
            display: block;
            margin-bottom: 28px;
            margin-right: 14px;

            svg {
                width: 16px;
                height: 16px;
            }

            span {
                display: none;
            }
        }

        &__img {
            margin-right: 12px;
            width: 68px;
            height: 69px;
        }


        &__bonus-info {
            position: relative;
        }

        &__title {
            margin-bottom: 18px;
        }

        &__total-price {
            margin-bottom: 2px;
        }

        &__wrap-price {
            width: 100%;
            text-align: left;
            margin-bottom: 14px;
        }
    }

}
