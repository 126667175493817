.calc {
    padding: 70px 0 120px;

    @media(max-width: 768px) {
        padding: 35px 0 0;
        margin-bottom: 40px;
        background: #F7F8FA;
    }

    .container {
        background: #F7F8FA;
        padding: 60px 50px;
        border-radius: 6px;

        @media(max-width: 768px) {
            padding: 40px 10px;
        }
    }

    &__title {
        text-align: left;
        margin-bottom: 58px;

        @media(max-width: 768px) {
            margin-bottom: 28px;
        }
    }

    &__wrap {
        display: flex;

        @media(max-width: 768px) {
            flex-direction: column;
        }

        &--first {
            margin-bottom: 64px;

            @media(max-width: 768px) {
                background-color: #fff;
                margin: 0 -20px 10px;
                padding: 15px 20px;

                .options__item {
                    margin-bottom: 12px;
                }
            }
        }

        &--second {
            justify-content: space-between;
            align-items: center;
            font-size: 24px;
            line-height: 150%;

            span {
                &:last-child {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 150%;
                    margin-left: 46px;

                    @media(max-width: 768px) {
                        font-size: 18px;
                        line-height: 150%;
                    }
                }
            }

            @media(max-width: 980px) {
                flex-direction: column;
                align-items: normal;
            }

        }

        .options {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:first-child {
                margin-right: 120px;

                @media(max-width: 768px) {
                    margin: 0 -10px 20px;
                    padding: 20px 10px;
                    background-color: #fff;
                }
            }

            &__title {
                position: relative;

                &-icon {
                    color: #2e58c1;
                    cursor: pointer;
                }
            }
        }

    }

    &__total {
        @media(max-width: 768px) {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            line-height: 150%;
        }
    }

    &__shelf {
        width: 140px;
        display: flex;
        align-items: center;
    }

    &__main-btn {
        margin-top: 64px;

        @media(max-width: 768px) {
            margin-top: 20px;
            .button {
                width: 100%;
            }
        }
    }

    .tooltip {
        font-weight: 400;
        position: absolute;
        width: 282px;
        background: #fff;
        padding: 15px;
        z-index: 4;
        box-shadow: 3px 3px 17px rgba(0, 0, 0, .1);
        right: -247px;
        top: 30px;
        line-height: 1.5;

        p {
            margin-bottom: 10px;
        }

        &:before {
            content: '';
            position: absolute;
            top: -9px;
            left: 22px;
            width: 16px;
            height: 16px;
            background-color: #ffffff;
            border-top: solid 1px #c1d3d2;
            border-left: solid 1px #c1d3d2;
            transform: rotate(45deg);
        }

        &__close {
            font-size: 20px;
            position: absolute;
            top: 7px;
            right: 7px;
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        .options__title {
            width: 100%;
        }
        .tooltip {
            width: 100%;
            left: 0;
        }

    }
}
