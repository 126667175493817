.pricelist {
    padding-top: 50px;
    padding-bottom: 50px;

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #ebedf2;
        margin-bottom: 20px
    }

    &__title {
        width: 70%;
        color: $dark;

        &:hover,
        &:focus,
        &:active{
            text-decoration: underline;
            color: $dark;
        }
    }

    &__date {
        width: 15%;
    }

    &__button {
        width: 15%;
        height: 40px;
    }

    &__header {
        font-weight: 700;

        .pricelist__title {
            &:hover,
            &:focus,
            &:active{
                text-decoration: none;
            }
        }
    }
}

@media  (max-width: 768px) {
    .pricelist {

        &__title {
            width: 100%;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            display: block;
            margin-bottom: 10px;
        }

        &__date {
            width: 50%;
        }

        &__button {
            width: 50%;
        }

        &__header {
            display: none;
        }
    }
}
