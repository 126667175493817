.select-categories {

    .container-sliders {
        position: relative;
    }

    &__wrap-btn {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    &__btn {
        font-size: 20px;
        line-height: 150%;
        padding: 16px 46px;
        background: #F7F8FA;
        color: #B2B2B2;
        transition: 0.2s;

        @media(max-width: 768px) {
            font-size: 14px;
            line-height: 150%;
            padding: 10px;
        }

        &:hover, &.is-active {
            color: $dark;
            background: #F0F1F7;
        }

        &:first-child {
            border-radius: 6px 0 0 6px;
        }

        &:last-child {
            border-radius: 0 6px 6px 0;
        }
    }

    .products-slider {
        display: none;

        &.is-active {
            display: flex;
        }
    }
}
