.usernav {
    display: flex;
    align-items: center;
    position: relative;

    &__start {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;

        .sidenav__login {
            border: none;
            margin: 0;
        }
    }

    &__username {
        font-weight: 500;
        color: $dark;
        text-decoration: none;
        font-size: 14px;
        line-height: 18px;

        &:hover,
        &:active,
        &:focus {
            outline: none;
            text-decoration: underline;
        }
    }

    &__expand {
        margin-left: 3px;
        padding: 5px;
    }

    &__expand-btn {
        padding: 5px;
    }

    &__list {
        position: absolute;
        top: 100%;
        z-index: 2;
        background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
        box-shadow: 0 18px 20px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 6px;
        display: none;
        padding: 0 30px;
        list-style: none;
        width: 134px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid #EBEDF2;
        padding: 15px 0;

        &:hover,
        &:active,
        &:focus {
            a {
                outline: none;
                text-decoration: underline;
            }
        }

        &:last-of-type {
            border: none;
        }
    }

    &__link {
        color: $dark;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
    }
}

@media (max-width: 768px) {
    .usernav:not(.sidenav__usernav) {
        display: none;
    }
}
