.profile {
    margin-top: 40px;
    margin-bottom: 40px;
}

.profile__button {
    margin-top: 40px;
    position: relative;
}

.profile__button:disabled {
    filter: grayscale(1);
    cursor: no-drop;
}

.profile__button .lds-ring {
    left: 9%;
}

.profile__send-email {
    width: auto;
    padding: 0  75px;
    height: 48px;
    margin: 15px;
    position: relative;
}

.profile__send-email i {
    margin-left: 15px;
    font-size:20px;
}

@media (max-width: 768px) {
    .profile__send-email {
        width: 100%;
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .profile__email-verified .alert{
        width: 100%;
    }
}
