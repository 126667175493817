.not-found {
    display: block;
    text-align: center;
    min-height: 100vh;
    background: linear-gradient(105.61deg, #2E58C1 3.48%, #4E2EC1 87.75%);
    padding-top: 6%;

    &__logo {
        width: 536px;
        height: 294px;
        margin: 0 auto;
    }

    #404 {
        width: 100%;
        height: 100%;
    }

    &__title {
        font-weight: bold;
        font-size: 54px;
        line-height: 66px;
        color: #fff;
        margin-bottom: 52px;
    }

    &__description {
        font-size: 18px;
        line-height: 190%;
        color: #FFFFFF;
        margin-bottom: 86px;
    }

    &__btns {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }

    &__link {
        display: inline-block;
        min-width: 300px;
        padding: 19px 40px 20px;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: 0.25s;
        letter-spacing: 0.05em;

        svg {
            display: inline-block;
            margin-bottom: 2px;
            width: 12px;
            height: 8px;
        }

        p {
            display: inline-block;
        }
    }

    &__link--prev {
        border: 1px solid #fff;
        color: #fff;
        margin-right: 32px;

        svg {
            margin-right: 18px;
            fill: #fff;
        }

        &:hover {
            background-color: #fff;
            color: $dark;

            svg {
                fill: $dark;
            }
        }

        &:active {
            background-color: rgb(236, 236, 236);
        }
    }

    &__link--next {
        background-color: #FCE885;
        color: #1A1A1A;

        svg {
            margin-left: 18px;
            transform: rotate(180deg);
            fill: #1A1A1A;
        }

        &:hover {
            background-color: #fcdc3d;
        }

        &:active {
            background-color: #d1b215;
        }
    }

}

@media (max-width: 960px) {
    .not-found {
        &__logo {
            width: 60%;
            height: auto;
            margin-bottom: 24px;
        }
    }
}

@media (max-width: 728px) {
    .not-found {
        padding-top: 8%;

        &__logo {
            width: 90%;
        }

        &__link {
            display: block;
            width: 80%;
            font-weight: 500;
            font-size: 11px;
        }

        &__btns {
            display: inline-block;
            margin: 0 auto;
        }

        &__link--prev {
            margin-right: 0;
            margin-bottom: 24px;
        }

        &__title {
            font-size: 28px;
            line-height: 1.4;
            margin-bottom: 32px;
        }

        &__description {
            font-size: 10px;
            margin-bottom: 32px;
        }


    }
}