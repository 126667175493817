.gallery__top {
	width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
}


.gallery__slide-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 570px;
    height: 570px;
}

.gallery__thumbs-slide{
	opacity: 0.4;
	width: 106px;
	margin-right: 10px;
}

.gallery__thumbs .swiper-slide-thumb-active {
	opacity: 1;
}

.gallery__buttons {
	display: none;
}

.gallery__slide--nophoto {
    width: 570px;
    height: 570px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
	.gallery__buttons {
		display: block;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 2;

		.buttonIcon {
			margin-bottom: 5px;
			height: 40px;
			width: 40px;
			background: transparent;
		}
	}

    .gallery__slide-link {
        width: 320px;
        height: 320px;
        margin: auto;
    }

    .gallery__slide--nophoto {
        width: 320px;
        height: 320px;
        margin:auto;
    }

}
