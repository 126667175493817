.catalog__filter-bg {
    visibility: hidden;
    opacity: 0;
}

.btn-filter {
    display: none;
    padding: 11px 0;
    width: 90%;
    background: #2E58C1;
    color: #fff;
    border-radius: 3px;
    margin: 12px auto;
}


.bc-active {
    background-color: $bc-gray;
}

#mse2_selected {
    strong {
        margin-right: 15px;
    }
}
.mse2_selected_link {
    color: $dark;
    display: inline-block;
    margin: 5px 15px 5px 0;

    i {
        margin-left: 5px;
        position: relative;
        top: -8px;
    }
}

.filters {
    &__form {
        border-top: 1px solid #ebedf2;
    }

    &__option {
        border-bottom: 1px solid #E4E6EB;

        label {
            position: relative;
            width: 100%;
        }
    }

    &__sup {
        margin-left: 4px;
        color: #666666;
        line-height: 300%;


        position: absolute;
        top: 0;
        right: 0;
    }

    &__checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    &__checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 12px;
        line-height: 150%;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &__checkbox + label::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #EBECF2;
        border-radius: 3px;
        margin-right: 14px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        background-color: #fff;
    }

    &__checkbox:checked + label::before {
        border-color: $blue;
        background-color: $blue;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &__checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-color: #b3d7ff;
    }

    &__checkbox:not(:disabled):active + label::before {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
    }

    &__checkbox:focus + label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &__checkbox:focus:not(:checked) + label::before {
        border-color: #80bdff;
    }

    &__checkbox:disabled + label::before {
        background-color: #e9ecef;
    }
}

.filters-item {
    position: relative;
    margin-right: 0;

    &__title {
        position: relative;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        padding: 10px 5% 10px;
        cursor: pointer;
        border-bottom: 1px solid #EBEDF2;

        span {
            display: block;
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__options {
        background-color: #F7F8FA;
        border-top: 1px solid #EBEDF2;
        border-bottom: 1px solid #EBEDF2;
        padding: 0 10px;
        height: 0;
        opacity: 0;
        transform: rotateX(90deg);
        transition: all 0.2s ease 0s;
        visibility: hidden;
        transform-origin: top center;
    }
}

.show-elem {
    height: auto;
    opacity: 1;
    transform: rotateX(0deg);
    visibility: visible;
    z-index: 3;
}

.rotateElem {
    svg {
        transform: rotate(180deg);
    }
}

@media screen and (max-width: 960px) {
    .filters {
        border-top: 1px solid #EBEDF2;

        background-color: #ffffff;
        position: absolute;
        width: 100%;
        z-index: 9999;


        &:first-child {
            padding-top: 2px;
        }

        height: 0;
        opacity: 0;
        transform: rotateX(90deg);
        transition: all 0.2s ease 0s;
        visibility: hidden;
        transform-origin: top center;

        &--show {
            height: auto;
            opacity: 1;
            transform: rotateX(0deg);
            visibility: visible;
            z-index: 4;
            left: 0;
            top: 56px;
        }

        &__checkbox + label {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .catalog__filter-bg {
        position: absolute;
        background-color: rgba(22, 19, 19, 0.2);
        backdrop-filter: blur(1px);
        width: 100%;
        height: 100%;
        z-index: 3;
        top: 56px;
    }

    .sticky {
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: 120px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(26, 26, 26, 0.2);
        backdrop-filter: blur(1px);
    }

    .sticky--fixed {
        top: 0;
        left: 0;
        height: 100%;
        position: fixed;
    }

    .btn-filter {
        display: block !important;
    }

    .bc-active {
        background-color: #fff;
    }

    .filters__option {
        background-color: #F7F8FA;
        border-top: 1px solid #EBEDF2;
        border-bottom: 1px solid #EBEDF2;
        padding: 0 10px;

        label .filters__sup {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .show-elem {
        height: auto;
        opacity: 1;
        transform: rotateX(0deg);
        visibility: visible;
        z-index: 9999;
    }
}

@media screen and (max-width: 728px) {
    .filters {
        &__filter {
            margin-right: 0;
        }
    }
}
