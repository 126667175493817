.news {

  @include indexBtn();

  padding: 70px 0 130px;
  background-color: #F7F8FA;

  @media(max-width: 768px) {
    padding: 30px 0 60px;
  }

  .container {
    position: relative;
  }

  &__wrap {
    position: relative;
  }

  &__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 60px;

    @media(max-width:1600px){
      max-width: 60%;
    }

    @media(max-width:768px) {
      margin-bottom: 24px;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
    }

  }

  &__btn-wrap {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    &--title {
      @media(max-width: 768px) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media(max-width: 768px) {
        position: relative;
        text-align: center;
        display: block;
      }

      .news__btn {
        font-size: 10px;
      }
    }
  }

  &__btn {
    position: relative;
    border-radius: 3px;
    color: #2E58C1;
    font-size: 14px;
    line-height: 200%;
    padding: 12px 50px 12px 35px;
    border: 1px solid #2E58C1;
    transition: 0.2s;

    &:hover {
      background-color: #2E58C1;
      color: #fff;

      &:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='8' fill='none' viewBox='0 0 13 8'%3e%3cpath fill='%23fff' d='M12.482 4.058a.505.505 0 000-.71L9.3.147a.498.498 0 00-.707 0 .505.505 0 000 .71l2.828 2.845-2.828 2.844a.505.505 0 000 .711.498.498 0 00.707 0l3.182-3.2zm-11.75.148h11.396V3.2H.732v1.006z'/%3e%3c/svg%3e");
      }
    }

    &:after {
      content: '';
      z-index: 2;
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 30px;
      width: 12px;
      height: 8px;
      transition: 0.2s;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 13 8'%3e%3cpath fill='%232E58C1' d='M12.482 4.058a.505.505 0 000-.71L9.3.147a.498.498 0 00-.707 0 .505.505 0 000 .71l2.828 2.845-2.828 2.844a.505.505 0 000 .711.498.498 0 00.707 0l3.182-3.2zm-11.75.148h11.396V3.2H.732v1.006z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
    }
  }
    &__container {
        overflow:hidden;
    }

    &__picture {
    width: 100%;
    height: 270px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px 6px 0 0;

      @media(max-width:768px) {
        height: 270px;
      }

    }

  }

  &__slide {
    background-color: #fff;
    border-radius: 6px;
    transition: 0.2s;
    height: auto;

    &:hover {
      box-shadow: 0 27px 17px -18px rgba(199, 203, 206, 0.72);
    }

    @media(max-width: 768px) {
      margin-bottom: 20px;
    }

  }

  &__sub-title {
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    transition: 0.2s;
    margin-bottom: 12px;

    &:hover {
      text-decoration-line: underline;
      color: #2E58C1;
    }

    @media(max-width:768px) {
        font-size: 14px;
        margin-bottom: 8px;
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 20px;

    @media(max-width:768px) {
        font-size: 14px;
        margin-bottom: 8px;
    }

  }

  &__wrap-text {
    padding: 30px 35px;

    @media(max-width:768px) {
      padding: 14px 20px 30px;
    }
  }

  &__date {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #2E58C1;

    @media(max-width:768px) {
        font-size: 10px;
    }
  }

  .swiper-wrapper {
    padding-bottom: 40px;

    @media(max-width: 480px) {
      flex-direction: column;
      padding-bottom: 20px;
    }
  }

}
