.alert {
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border:1px solid transparent;
    background: transparent;

    &--success {
        color: #0f5132;
        background: #d1e7dd;
        border-color: #badbcc;
    }

    &--error {
        color: #842029;
        background: #f8d7da;
        border-color: #f5c2c7;
    }

    &--warning {
        color: #664d03;
        background: #fff3cd;
        border-color: #ffecb5;
    }
}
