.support {
    &__sections {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid $gray;
    }

    &__sectionsTitle {
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 25px;
    }

    &__sectionsList {

    }

    &__sectionsItem {
        margin-bottom: 15px;

        &--active {
            .support__sectionsLink {
                color: $blue;
                text-decoration: underline;
            }
        }
    }

    &__sectionsLink {
        color: #000;
        font-weight: 700;
        text-decoration: none;
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }



    @media (max-width: 768px) {
        .pricelist__item {
            .pricelist__button {
                width: 100%;
            }
        }
    }
}
