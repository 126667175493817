.product {
	position: relative;
	margin-bottom: 35px;
	transition: all 500ms ease-in-out;
	width: 250px;
	border-radius: 6px;

	&:hover,
	&:focus {
		z-index:3;

		.product__container {
			box-shadow: 0 10px 30px rgba(7, 14, 32, 0.12);
			position: absolute;
		}

		.product__bottom {
			display: block;
		}

		.product__form {
			right: 15px;
		}

		.product__tools {
			right: 15px;
		}

		.product__title {
			color: $blue;
			text-decoration: underline;
		}
	}
}

.product__container {
	margin: 20px auto;
	background-color: #fff;
	padding:20px;
	border-radius: 6px;
}

.product__top {
	position: relative;
	overflow: hidden;
}

.product__image-link {
	width: 100%;
	height: 265px;
	padding:15px;
	background: #F6F6F6;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
}

.product__labels {
	position: absolute;
	top: 15px;
	left: 15px;
}

.product__form {
	position: absolute;
	bottom: 15px;
	right: -50px;
	transition: right 300ms ease-in-out;
}

.product__tools {
	position: absolute;
	top: 15px;
	right: -25px;
	transition: right 300ms ease-in-out;

	.buttonIcon {
		margin-bottom: 17px;
	}
}

.product__center {
	margin-top: 15px;
}

.product__title {
	line-height: 21px;
	text-decoration: none;
	color: $dark;

	&:focus,
	&:hover {
		color: $blue;
		text-decoration: underline;
	}
}

.product__prices {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.product__price {
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	margin: 0;
}

.product__old-price {
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	color: $gray;
	text-decoration: line-through;
	margin: 0;
	margin-right: 5px;
}

.product__bottom {
	display: none;
}

.product__description {
	font-size: 12px;
	line-height: 18px;
	color: $gray;
}


.products--small {
	.product {
		padding: 0;
	}

	.product__container {
		margin: 0 auto;
		margin-bottom: 10px;
		padding:15px;
	}

	.product__image-link {
		height: 210px;
	}
}
