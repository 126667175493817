.contacts {
    padding: 40px 0;

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        background-color: $bgGray;
        padding: 25px;
    }

    &__item {
        width: 33.3333%;
        padding: 25px;
        border-right: 2px solid #fff;

        &:last-of-type {
            border: none;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        margin-bottom: 20px;
    }

    &__text {
        color: $dark;
        font-size: 14px;
        line-height: 220%;
        font-weight: 600;
    }

    &__link {
        color: $dark;
        display: block;
    }

    @media (max-width: 768px) {
        &__grid {
            padding: 0;
        }

        &__item {
            width: 100%;
            border-right: none;
            border-bottom: 2px solid #fff;

        }
    }
}
