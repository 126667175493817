.solutions {
    padding: 120px 0;
    margin-bottom: 120px;
    background-color: #F7F8FA;

    @media (max-width: 768px) {
        padding: 50px 0;
        margin-bottom: 0;
    }

    &--mb {
        margin-bottom: 0;
    }

    &--mobile {
        display: none;

        @media(max-width: 768px) {
            display: block;
            background-color: #fff;

            .solutions__wrap {
                background-color: #F7F8FA;
            }

            .solutions__item {
                background-color: #fff;
            }

        }
    }

    &__wrap-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 64px;

        @media(max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media(max-width: 768px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    &__wrap {
        background-color: #fff;
        border-radius: 6px;
        padding: 40px 30px;
        position: relative;

        @media(max-width: 768px) {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 35px 0 0;
            height: auto;
        }

        &:first-child, &:nth-child(2) {
            width: 49%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2%;

            .solutions__sub-wrap {
                min-width: 50%;
                margin-right: 15px;

                @media(max-width: 768px) {
                    margin: 0;
                    height: auto;
                }
            }

            @media(max-width: 1200px) {
                flex-direction: column;
                justify-content: start;
            }

            @media(max-width: 768px) {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding: 35px 0 0;
                height: auto;
                width: 100%;
            }
        }

        &:nth-child(3), &:nth-child(4) {
            margin-right: 2%;

            @media(max-width: 768px) {
                margin-right: 0;
            }
        }

        &:nth-child(3), &:nth-child(4), &:nth-child(5) {
            flex: 1;
            height: auto;
        }
    }

    &__sub-wrap {
        @media(max-width: 768px) {
            width: 100%;
        }
    }

    &__picture {
        vertical-align: center;
        display: flex;

        @media(max-width: 1200px) {
            height: 180px;
        }

        @media(max-width: 768px) {
            display: block;
            position: absolute;
            top: 10px;
            right: 20px;
            height: 70px;
            width: 25%;
            padding: 10px 0;
        }

        img {
            margin: auto;
            width: 100%;
            height: 180px;
            object-fit: contain;

            @media(max-width: 768px) {
                height: 100%;
                margin: 0;
            }
        }

    }

    &__title {
        font-size: 30px;
        line-height: 150%;
        max-width: 60%;

        span {
            font-weight: bold;
        }

        @media(max-width: 768px) {
            margin-bottom: 24px;
            max-width: 100%;

            font-size: 16px;
            line-height: 150%;
        }
    }

    &__sub-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        margin-bottom: 20px;

        @media(max-width: 768px) {
            font-weight: 600;
            max-width: 70%;
            font-size: 14px;
            line-height: 18px;
            padding: 0 20px;
            margin-bottom: 32px;
        }
    }

    &__item {
        list-style: inside;
        color: #2E58C1;
        font-size: 8px;

        &:hover {
            color: #2E58C1;
        }

        @media(max-width: 768px) {
            list-style: none;
            background-color: #F7F8FA;
            border-bottom: 1px solid #EBECF2;
            padding-left: 10px;

            &:last-child {
                border: none;
            }
        }

        span {
            color: $dark;
            font-size: 14px;
            line-height: 220%;

            @media(max-width: 768px) {
                font-size: 11px;
                line-height: 360%;
            }
        }
    }

    &__list {
        @media(max-width: 768px) {
            display: none;

            &.is-opened {
                display: block;
            }
        }
    }

    &__link {
        &:hover span {
            color: #2E58C1;
            text-decoration: underline;
        }

        @media(max-width: 768px) {
            padding: 0 20px;
        }
    }

    &__button {
        position: relative;

        @media(max-width: 768px) {
            width: 100%;
        }
    }

    .button {
        @media(max-width: 768px) {
            height: 50px;
        }
    }

    &__arrow-btn {
        position: absolute;
        left: -80px;
        top: -45px;
        width: 118px;
        height: 80px;

        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 118 81'%3e%3cpath fill='%23000' d='M117.245 76.249a.5.5 0 010 .707l-3.182 3.182a.5.5 0 01-.707-.707l2.828-2.829-2.828-2.828a.5.5 0 11.707-.707l3.182 3.182zM1.413.899c6.432 31.194 22.963 49.97 44.026 60.966 21.09 11.01 46.755 14.237 71.452 14.237v1c-24.761 0-50.616-3.23-71.915-14.35C23.651 51.618 6.927 32.593.434 1.1l.979-.202z'/%3e%3c/svg%3e ");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @media(max-width: 768px) {
            transform: scale(-1, 1);
            left: 250px;
            width: 90px;
        }

        @media(max-width: 440px) {
            width: 70px;
            left: 220px;
            top: -35px;
        }
    }

}
