.label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.label__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 24px;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 9px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.label__item-red {
    color: #fff;
    background: #C12E33;
}

.label__item-blue {
    color: #fff;
    background: #92CCDB;
}

.label__item-yellow {
    color: #000;
    background: #fce885;
}

