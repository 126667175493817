.cart {
    padding-bottom: 110px;

    &__main-title {
        display: none;
    }

    &__fast-order {
        display: flex;
        flex-wrap: wrap;

        &--one-click {
            margin-right: 30px;
        }
    }
}

.cart__wrap {
    padding: 70px 0 0;
    display: flex;
    justify-content: space-between;
}

.cart__info {
    width: 350px;


    .promo-code {
        margin-bottom: 36px;
    }
}

@media (max-width: 1200px) {
    .cart__cart-products {
        width: 68%;
    }

    .cart__info {
        width: 30%;
    }
}

@media (max-width: 980px) {
    .cart__wrap {
        flex-direction:column;
    }

    .cart__info {
        width: 100%;
    }
}

@media (max-width: 728px) {
    .cart {
        padding-bottom: 52px;

        &__info-order {
            padding: 38px 18px;
        }

        &__fast-order {
            &--one-click {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        &__get-link {
            width: 100%;
        }
    }
}
