.promo-code {
    &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 24px;
    }

    &__form {
        display: flex;
        position: relative;
    }

    &__form--cancel {
        display: none;

        .promo-code__form-input {
            cursor: no-drop;
            color: $bgColor;
        }
    }

    &__success-text {
        display: none;
    }

    &--active {
        .promo-code__form--set {
            display: none;
        }

        .promo-code__form--cancel {
            display: flex;
        }

        .promo-code__success-text {
            display: block;
        }
    }


    &__wrap {
        position: relative;
        width: 60%;

        svg {
            position: absolute;
            top: 0;
            right: -20px;
            width: auto;
            height: 100%;
            z-index: 3;
            opacity: 0.1;
        }
    }

    &__form-input {
        position: relative;
        background-color: $bgGray;
        padding: 13px 28px;
        border: 1px solid transparent;
        width: 100%;
        font-weight: 600;
        border-radius: 4px 0 0 4px;

        &:active,
        &:focus {
            border: 1px solid $yellow;
        }

        &::-webkit-input-placeholder {
            font-weight: 400;
        }

        &::-moz-placeholder {
            font-weight: 400;
        }

        &:-moz-placeholder {
            font-weight: 400;
        }

        &:-ms-input-placeholder {
            font-weight: 400;
        }
    }

    &__btn {
        display: block;
        z-index: 3;
        background-color: $bc-btn;
        color: $dark;
        width: 40%;
        padding: 14px 0;
        transition: 0.3s;
        text-align: center;
        border-radius: 0 4px 4px 0;

        &:hover {
            background-color: #fcdc3d;
        }

        &:active {
            background-color: #d1b215;
        }
    }
}

@media (max-width: 728px) {
    .promo-code {
        &__form-input {
            padding: 13px 18px;
        }
    }
}
