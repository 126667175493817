.NewsItem {
    display: flex;
    flex-direction: column;
    width: calc(33.3% - 10px);
    max-width: 370px;

    margin-bottom: 20px;

    background-color: #fff;
    border-radius: 6px;
    transition: 0.2s;
    height: auto;
    position: relative;

    &:hover {
        box-shadow: 0 27px 17px -18px rgba(199, 203, 206, 0.72);
    }

    &__content {
        padding: 20px;

    }

    &__footer {
        padding: 20px;
        border-top: 1px dashed $dark;
    }

    &__title {
        color:$dark;
        font-weight: 700;
        min-height: 3em;
        display: block;
    }

    &__date {
        font-size: 12px;
    }

    &__tags {
        position: absolute;
        left: 10px;
        top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__tag {
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 10px;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 4px;
        background: $blue;
    }
}



@media (max-width: 1200px) {
    .NewsItem {
        width: calc(33.3% - 10px);
    }
}

@media (max-width: 768px) {
    .NewsItem {
        width: calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .NewsItem {
        width: 100%;
        max-width: 370px;
    }
}

