.desktopSidenav {
	background-color: #fff;
	position: fixed;
	display: block;
	z-index: 4;

	left: -100%;
	transition: left $transitionTime ease-in-out;

	width: 34%;
	height: 100vh;

	&--open {
		left: 0;
	}
}

.desktopSidenav__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: $bgGray;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.desktopSidenav__socials {
	margin-right: 70px;
}

.desktopSidenav__logo {
	display: inline-block;
	margin-left: 58%;
	margin-top: 10px;
	margin-bottom: 19px;
}

.desktopSidenav__pages {
	padding: 0;
	margin: 0;
	list-style: none;
}

.desktopSidenav__page {
	border-bottom: 1px solid $borderColor;
	padding-left: 58%;

	&:first-of-type {
		border-top: 1px solid $borderColor;
	}

	&:hover {
		background-color: $bgGray;

		a {
			font-weight: 600;
		}
	}

	a {
		height: 40px;
		display: flex;
		align-items: center;

		text-decoration: none;
		color: $dark;
		line-height: 56px;
	}
}

.desktopSidenav__address {
	margin-left: 58%;
	position: absolute;
	bottom: 116px;
	line-height: 21px;

	strong {
		font-weight: 600;
		display: block;
		line-height: 28px;
	}
}

.desktopSidenav__email {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	color: $dark;
	display: block;
	margin-top: 20px;
}

@media (max-width: 1600px) {
	.desktopSidenav {
		width: 30%;
	}

	.desktopSidenav__logo {
		margin-left: 35%;
	}

	.desktopSidenav__page {
		padding-left: 35%;
	}

	.desktopSidenav__address {
		margin-left: 35%;
	}
}

@media (max-width: 1200px) {
	.desktopSidenav {
		display: none;
	}
}
