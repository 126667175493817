.sort {
    min-height: 76px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &__icon {
        margin-right: 5px;
    }

    &__open {
        margin-left: 5px;
    }

    &__open--open {
        transform: rotateX(180deg);
    }

    &__button {
        padding: 20px;
        background-color: #F5F6FA;
        display: flex;
        align-items: center;

        &:hover {
            background: #fff;
        }
    }

    &__list {
        height: 0;
        opacity: 0;
        transform: rotateX(90deg);
        transition: all 0.2s ease 0s;
        visibility: hidden;
        transform-origin: top center;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 100%;
        box-shadow: 3px 3px 7px rgba(0,0,0,0.1);
        background: #fff;
        min-width: 100%;

        &--open {
            height: auto;
            opacity: 1;
            transform: rotateX(0);
            visibility: visible;
            z-index: 3;
        }
    }

    &__item {
        white-space: nowrap;
        font-size: 14px;
        line-height: 1.7;
        padding: 4px 10px 6px;
        cursor: pointer;
        border-bottom: 1px solid #ebedf2;

        &:hover {
            background-color: #F5F6FA;
        }


        &--selected {
            cursor: not-allowed;
            background: #F5F6FA;
            color: #aaa;
        }
    }
}
