.order-btns {
    display: block;

    &__btn {
        transition: 0.25s;
        border-radius: 4px;
    }
}

.order-btns__btn-one-click {
    background-color: $bc-btn;
    color: $dark;
    font-weight: 500;
    padding: 20px 40px;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 24px;

    &:hover {
        background-color: #fcdc3d;
    }

    &:active {
        background-color: #d1b215;
    }
}

.order-btns__btn-get-link {
    line-height: 150%;
    color: $blue;
    border: 1px solid $blue;
    padding: 18px 40px 17px;
    display: inline-block;

    &:hover {
        background-color: $blue;
        color: #fff;
    }

    &:active {
        background-color: $activeBlue;
    }
}

@media (max-width: 980px) {
    .cart__fast-order {
        padding-bottom: 36px;
    }

    .order-btns {
        &__form {
            display: block;
        }

        &__btn-one-click {
            margin-right: 2%;
        }

        &__btn-get-link {
            margin: 0 -2px;
        }

        &__btn {
            display: inline-block;
            width: 49%;
        }
    }
}

@media (max-width: 728px) {
    .order-btns{
        &__btn-get-link, &__btn-one-click {
            margin: 0;
            display: block;
            width: 100%;
            margin-bottom: 16px;
        }
    }
}
