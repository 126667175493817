.products-card {
    width: 100%;

    &__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        position: relative;
        background-color: #fff;
        width: 210px;
        min-height: 380px;
        font-size: 14px;
        line-height: 150%;
        margin: 15px;
    }

    &__labels {
        position: absolute;
        top: 14px;
        left: 14px;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 150%;
        color: #fff;
    }

    &__bg {
        z-index: 1;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    &__img {
        position: relative;
        display: block;
        padding: 5px;
        background-color: $bgGray;
        border-radius: 6px;
        margin-bottom: 16px;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    &__link {
        color: $dark;

        &:hover {
            color: $blue;
            text-decoration-line: underline;
        }
    }

    &__title {
        margin-bottom: 12px;
        font-weight: normal;
    }

    &__price {
        font-weight: 600;
        color: $dark;
        margin-bottom: 12px;

        span {
            font-size: 12px;
            text-decoration-line: line-through;
            margin-right: 14px;
        }
    }

    &__wrap-hidden {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    &__description {
        font-size: 12px;
        margin-bottom: 12px;


    }

    &__status {
        font-size: 12px;
        position: relative;
        margin-left: 16px;

        p {
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 5px;
                left: -16px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }
    }

    &__availability::before {
        background-color: #3E8725;
    }

    &__close::before {
        background-color: #F3A845;
    }

    &__off::before {
        background-color: #C12E33;
    }
}

.products-card__item:hover {

    .products-card__bg {
        z-index: 2;
        width: calc(100% + 40px);
        top: 20px;
        left: 20px;
        padding: 20px;
        margin: -40px;
        background: #FFFFFF;
        box-shadow: 0px 10px 30px rgba(7, 14, 32, 0.12);
        border-radius: 6px;
    }

    .products-card__wrap-hidden {
        display: block;
        opacity: 1;
        visibility: visible;
    }

    .favorite-btns,
    .actionButtons,
    .cart-btn {
        display: block;
    }

}

@media (max-width: 1200px) {
    .products-card__item {
        margin: 10px;
        width: calc(25% - 20px);
    }

    .products-card__item:hover {
        .products-card__bg {
            width: 100%;
            top: 20px;
            left: 20px;
            padding: 0px;
            margin: -20px;
            background: #FFFFFF;
            box-shadow: none;
        }

        .products-card__wrap-hidden {
            display: none;
        }
    }
}


@media (max-width: 980px) {
    .products-card {

        &__item {
            margin: 10px;
            width: calc(50% - 20px);
        }

        &__bg {
            position: relative;
            width: calc(100%);
            padding: 0;
            margin: 0;
        }

        &__wrap-hidden {
            display: none;
        }
    }
}

@media (max-width: 728px) {
    .products-card {
        &__title{
            font-size: 14px;
            line-height: 130%;
            margin-bottom: 0;
        }

        &__price {
            font-size: 14px;

            span {
                font-size: 12px;
            }
        }
    }
}
