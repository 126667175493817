.productPage__header {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

.productPage__top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.productPage__left {
    width: 50%;
    padding-right: 10px;
}

.productPage__right {
    width: 50%;
    padding-left: 10px;
}

.productPage__article {
    line-height: 17px;
    margin: 0 40px 0 0;
}

.productPage__gallery {
    position: relative;
    overflow: hidden;
}

.productPage__labels {
    position: absolute;
    top: 23px;
    left: 30px;
    z-index: 2;
}

.productPage__title {
    @include header_2;
    margin-top: -11px;
}

.productPage__prices {
    display: flex;
    align-items: baseline;
}

.productPage__price {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    margin-right: 5px;
    margin-top: 11px;
    margin-bottom: 11px;
}

.productPage__old-price {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: $gray;
    text-decoration: line-through;
}

.productPage__diler-prices {

}

.productPage__diler-price {
    color: #484848;
    line-height: 21px;
    margin-bottom: 10px;
}

.productPage__diler-old-price {
    text-decoration: line-through;
    font-size: 0.9em;
}

.productPage__introtext {
    color: $gray;
    line-height: 21px;
    margin-bottom: 35px;

    p, li {
        color: $gray;
        line-height: 21px;
    }

    li {
        font-weight: 400;
        line-height: 31px;
        padding-left: 25px;
        background-image: url(/assets/template/images/svg/check.svg);
        background-repeat: no-repeat;
        background-position: left 9px;
    }
}

.productPage__buttons {
    display: flex;
    align-items: flex-start;
    padding-bottom: 40px;
    border-bottom: 1px solid #EBEDF2;
    margin-bottom: 20px;
}

.productPage__buttons-left {
    margin-right: 10px;
}

.productPage__inCart {
    margin-bottom: 10px;
    display: flex;

    .button--outline {
        width: 200px;
        border-radius: 3px 0 0 3px;
    }

    .button--blue {
        border-radius: 0 3px 3px 0;
        width: 70px;
    }

    &--hidden {
        display: none;

        & + form .productPage__add {
            margin-top: 0;
        }
    }
}

.productPage__add {
    margin: 10px 0;
}

.productPage__analogue {
    margin-bottom: 10px;
}

.productPage__buttons-right {
    display: flex;
    align-items: center;

    .buttonIcon {
        margin-right: 10px;
    }
}

.productPage__bonuses {
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.05em;
}

.productPage__getOptPrice {
    color: $superGray;
    text-decoration: none;
    border-bottom: 1px dashed $superGray;
    display: inline-block;
    margin-top: 20px;

    &:hover,
    &:focus {
        border-color: transparent;
    }
}

.productPage__delivery {
    margin-bottom: 33px;
}

.productPage__delivery-text {
    line-height: 24px;
    color: $superGray;
}

.productPage__vendor {
    height: 100px;
    width: 170px;
    background: #fff;
    border: 1px solid #EBECF2;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 32px;

    img {
        filter: grayscale(1);
        transition: all 300ms ease-in-out;
    }

    &-name {
        text-align: center;
        line-height: 1.5;
        font-size: 18px;
        color: $superGray;
    }

    &:hover,
    &:focus {
        img {
            filter: grayscale(0);
        }
    }
}

.productPage__features {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.productPage__feature {
    display: flex;
    align-items: flex-start;
    margin-right: 37px;

    &:last-of-type {
        margin-right: 0;
    }
}

.productPage__feature-icon {
    margin-right: 16px;
}

.productPage__feature-title {
    margin-top: -3px;
    margin-bottom: 5px;
    font-weight: 700;
    line-height: 21px;
}

.productPage__feature-text {
    margin-top: 0;
}


.productPage__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 87px;
}

.productPage__nav {
    position: sticky;
    top: 74px;
    width: 170px
}

.productPage__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.productPage__nav-item {
    margin-bottom: 20px;
}

.productPage__nav-link {
    font-weight: 700;
    line-height: 42px;
    color: $superGray;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 500ms ease-in-out;

    white-space: nowrap;

    &:before {
        content: '';
        background: $blue;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 3px;
        width: 0;
        transition: all 500ms ease-in-out;
    }


    &:hover,
    &:focus,
    &--active {
        color: $dark;
        position: relative;

        &:before {
            width: 30px;
            margin-right: 21px;
        }

    }
}

.productPage__content {
    width: calc(100% - 170px);
}

.productPage__content-block {
    background: $bgGray;
    margin-bottom: 30px;
    margin-left: 30px;
    width: 100%;
    padding: 50px;
}

.productPage__video-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.productPage__video-item {
    margin-bottom: 20px;
    width: 100%;
}


.productPage__related,
.productPage__looked {
    width: 100%;
    margin-left: 30px;
    margin-top: 88px;

    h2 {
        margin-bottom: 37px;
    }
}

.productPage__looked {
    margin-top: -200px;
}


.productPage__content-block.content {
    h1 {
        @include header_1;
        font-size: 20px;
        line-height: 190%;
        margin-bottom: 1.5em;
    }

    h2 {
        @include header_2;
        font-size: 20px;
        line-height: 190%;
        margin-bottom: 1.5em;
    }

    p, li {
        @include text;
        color: $dark;
        line-height: 27px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    li {
        margin-bottom: 1em;
    }

    .productPage__video-title {
        @include header_2;
        font-size: 16px;
        line-height: 190%;
        margin-bottom: 1.5em;
    }

    table:not([class]) {
        margin: 0;

        th {
            background: #fff;
        }

        td {
            background: #fff;
            border: 2px solid #F7F8FA;
        }
    }
}

.productPage__files-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    list-style: none;
    padding: 0;
    margin: 0;
}

.productPage__files-item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 23px;

    img {
        margin-right: 11px;
    }

    a {
        line-height: 27px;

        &:focus,
        &:hover {
            text-decoration: none;
            color: $purple;
        }
    }
}

.productPage__seo {
    background: $bgGray;
    padding: 100px 0;

    .productPage__seo_content {
        width: 970px;
        margin-left: 200px;

        h1 {
            font-size: 30px;
        }
    }

}

.productPage__modal-opt-product {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.productPage__modal-opt-product-image {
    max-width: 20%;
    margin-right: 10px;
}

.productPage__modal-opt-product-title {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}

.productPage__modal-opt-product-price {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
}

@media (max-width: 1200px) {
    .productPage__header {
        justify-content: space-around;
    }

    .productPage__right {
        width: 100%;
    }

    .productPage__left {
        width: 570px;
        margin: auto;
        padding: 0;
        margin-bottom: 20px;
    }

    .productPage__bottom {
        flex-wrap: wrap;
    }

    .productPage__nav {
        display: none;
    }

    .productPage__content {
        width: 100%;
    }

    .productPage__content-block {
        margin-left: 0;
    }

    .productPage__seo {
        .productPage__seo_content {
            width: 100%;
            margin-left: 0;
        }
    }
}

@media (max-width: 768px) {

    .productPage__header {
        justify-content: space-between;
    }

    .productPage__left {
        width: 100%;
    }

    .productPage__right {
        width: 100%;
        position: relative;
        padding-left: 0;
    }

    .productPage__gallery {
        width: 480px;
        margin: auto;
    }

    .productPage__buttons {
        flex-wrap: wrap;
    }

    .productPage__buttons-left {
        order: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .productPage__inCart {
        display: none;
    }

    .productPage__buttons-right {
        order: 0;
        width: 100%;

        .buttonIcon {
            display: none;
        }
    }

    .productPage__introtext {
        p, li {
            margin-bottom: 20px;
        }
    }

    .productPage__bonuses {
        margin-bottom: 15px;
    }

    .productPage__vendor {
        position: absolute;
        top: 0;
        right: 0;
    }

    .productPage__title {
        margin-right: 190px;
        margin-top: 0;
    }

    .productPage__prices {
        margin-right: 190px;
    }

    .productPage__delivery {
        border-bottom: 1px solid #ebedf2;
    }

    .productPage__video-item {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .productPage__gallery {
        width: 100%;
    }

    .productPage__title {
        font-size: 18px;
        margin-right: 0;
        line-height: 22px;
    }

    .productPage__prices {
        margin-right: 120px;
    }

    .productPage__price {
        font-size: 14px;
        line-height: 17px;
    }

    .productPage__old-price {
        font-size: 10px;
        line-height: 15px;
    }

    .productPage__introtext {
        font-size: 14px;
    }

    .productPage__vendor {
        position: static;
    }

    .productPage__getOptPrice {
        font-size:16px;
    }

    .productPage__bonuses {
        font-size: 12px;
    }

    .productPage__buttons-left {
        flex-wrap: wrap;

        .button, form {
            width: 100%;
        }
    }

    .productPage__feature-title {
        line-height: 1.5;
    }

    .productPage__features {
        flex-wrap: wrap;
    }


    .productPage__feature {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }

    .productPage__bottom {
        margin-top: 12px;
    }

    .productPage__content-block {
        margin-left: -10px;
        width: calc(100% + 20px);
        padding: 15px 10px;

        h2 {
            font-size: 12px;
            line-height: 36px;
            margin-bottom: 26px;
        }
    }

    .content.productPage__content-block {
        h2 {
            font-size: 12px;
            line-height: 36px;
            margin-bottom: 26px;
        }

        .productPage__video-title {
            font-size: 12px;
            line-height: 36px;
            margin-bottom: 26px;
        }
    }


    .productPage__files-item {
        width: 100%;
    }

    .productPage__seo {
        padding: 50px 0;
    }
}
