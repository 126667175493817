.report {
    padding: 120px 0 0;

    @media(max-width: 768px) {
        padding: 60px 0 0;
    }

    .container {
        position: relative;
    }

    @include indexBtn();

    &__title {
        margin-bottom: 64px;
        text-align: center;

        @media(max-width: 768px) {
            margin-bottom: 24px;
        }
    }

    &__slide{
        padding: 0 5% 0 20%;
        position: relative;

        @media(max-width: 768px) {
            padding: 0 0 0 20%;
        }
    }

    &__wrap {
        position: relative;
        background: #F6F6F6;
        padding: 50px 40px;
        border-radius: 6px;

        @media(max-width: 768px) {
            padding: 24px 20px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50px;
            left: -24px;
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='%23F6F6F6' d='M24 0H.5L24 23.5V0z'/%3e%3c/svg%3e ");

            @media(max-width: 768px) {
                width: 16px;
                height: 16px;
                left: -16px;
            }
        }
    }

    &__author {
        position: absolute;
        border-radius: 50%;
        left: calc(20% - 40px);
        top: 0;
        transform: translateX(-100%);
        width: 100px;
        height: 100px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media(max-width: 768px) {
            width: 14%;
            height: auto;
            left: calc(20% - 16px);
        }
    }

    &__name {
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        margin-bottom: 10px;

        @media(max-width: 768px) {
            font-size: 12px;
        }
    }

    &__company {
        font-size: 14px;
        line-height: 17px;
        color: #484848;
        margin-bottom: 24px;

        @media(max-width: 768px) {
            font-size: 10px;
            margin-bottom: 24px;
        }
    }

    &__sub-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 190%;
        margin-bottom: 16px;

        @media(max-width: 768px) {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }

    &__before {
        margin-bottom: 24px;

        .report__item {
            &:before {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 12 12'%3e%3cpath fill='%23C12E33' d='M4.595 10.483a.61.61 0 01-.866 0l-3.46-3.46a.919.919 0 010-1.3l.433-.433a.919.919 0 011.3 0l2.16 2.16 5.836-5.836a.919.919 0 011.3 0l.433.433a.919.919 0 010 1.3l-7.136 7.136z'/%3e%3c/svg%3e ");
            }
        }

    }

    &__after {
        margin-bottom: 24px;

        .report__item {
            &:before {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 12 12'%3e%3cpath fill='%232E58C1' d='M4.595 10.483a.61.61 0 01-.866 0l-3.46-3.46a.919.919 0 010-1.3l.433-.433a.919.919 0 011.3 0l2.16 2.16 5.836-5.836a.919.919 0 011.3 0l.433.433a.919.919 0 010 1.3l-7.136 7.136z'/%3e%3c/svg%3e ");
            }
        }

    }

    &__list {
        padding-left: 26px;
    }

    &__item {
        position: relative;
        font-size: 16px;
        line-height: 190%;
        margin-bottom: 10px;

        @media(max-width: 768px) {
            font-size: 10px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: -22px;
            width: 14px;
            height: 14px;
        }
    }

    .swiper-wrapper {
        padding-bottom: 80px;
    }

    .swiper-pagination-bullet-active {
        background-color: #1A1A1A;
    }
}
