.options {

    &__title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 24px;

        &--mb {
            margin-bottom: 0;
        }
    }

    &__item {
        display: inline-block;
        margin-right: 20px;
        font-size: 12px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__input-radio {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    /* для элемента label связанного с .custom-radio */
    &__input-radio+label {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }
    /* создание в label псевдоэлемента  before со следующими стилями */
    &__input-radio+label::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #adb5bd;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    /* стили при наведении курсора на радио */
    &__input-radio:not(:disabled):not(:checked)+label:hover::before {
        border-color: #b3d7ff;
    }
    /* стили для активной радиокнопки (при нажатии на неё) */
    &__input-radio:not(:disabled):active+label::before {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
    }
    /* стили для радиокнопки, находящейся в фокусе */
    &__input-radio:focus+label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    &__input-radio:focus:not(:checked)+label::before {
        border-color: #80bdff;
    }
    /* стили для радиокнопки, находящейся в состоянии checked */
    &__input-radio:checked+label::before {
        border-color: #0b76ef;
        background-color: #0b76ef;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3e%3cg clip-path='url(%23clip0_306_66)'%3e%3cpath fill='%23fff' d='M3.063 6.989a.407.407 0 01-.577 0L.18 4.682a.612.612 0 010-.866l.288-.29c.24-.239.627-.239.867 0l1.44 1.44 3.89-3.89c.24-.24.628-.24.867 0l.288.289c.24.24.24.627 0 .866L3.063 6.99z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_306_66'%3e%3cpath fill='%23fff' d='M0 0h8v8H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
    }
    /* стили для радиокнопки, находящейся в состоянии disabled */
    &__input-radio:disabled+label::before {
        background-color: #e9ecef;
    }
}
