// popular-products
.popular-products {
    font-size: 12px;
    line-height: 150%;
    @include reset-ul;
    margin-bottom: 24px;

    ul li {
        padding: 20px 0px;
        border-bottom: 1px solid #EBEDF2;
    }

    ul li:last-child {
        border-bottom: none;
    }

    a {
        text-decoration: none;
        color: #484848;
    }

    &__title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
    }

    &__slider {
        height: 360px;
    }

    &__link {
        display: flex;
        align-items: flex-start;

        &:hover {
            .popular-products__sub-title {
                color: #2E58C1;
                text-decoration-line: underline;
            }
        }
    }

    .product-status {
        display: none;
    }

    &__img {
        background-color: #F6F6F6;
        border-radius: 6px;
        padding: 20px 8px;
        width: 34%;
        margin-right: 6%;

        img {
            width: 100%;
            height: 40px;
            object-fit: contain;
        }
    }

    &__text {
        width: 60%;
    }

    &__price {
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #1A1A1A;

        span {
            display: none;
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
        }
    }
}

.popular-products__el-wrap-control {
    display: none;
    position: relative;
}

@media screen and (max-width: 980px) {
    .popular-products__el-wrap-control {
        display: block;
    }

    .popular-products ul li {
        border-bottom: none;
    }

    .popular-products {
        margin-bottom: 40px;

        &__title {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 32px;
        }
    }

    .popular-products {

        &__link {
            width: 100%;
            flex-direction: column;
        }

        .product-status {
            display: none;
        }

        &__img {
            width: 100%;
            margin-right: 0;
            padding: 12px 6px 20px;

            img {
                width: 100%;
                height: 240px;
                padding: 76px 24px;
                object-fit: contain;
            }
        }

        &__text {
            width: 100%;
            margin-top: 22px;

            .price {
                span {
                    display: inline-block;
                    margin-right: 8px;
                    text-decoration-line: line-through;
                }
            }
        }

        .product-status {
            margin-top: -10px;
        }
    }
}

@media screen and (max-width: 728px) {
    .popular-products__img {
        padding: 0px 0px 12px;
    }
}