.header__top {
	height: 77px;
	border-bottom: 1px solid #EBEDF2;

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}

.header__showSidenav,
.header__showDesktopSidenav {
	&:hover,
	&:active,
	&:focus {
		img {
			transition: transform $transitionTime ease-in-out;
			transform: scale(1.05);
		}
	}
}

.header__showSidenav {
	display: none;
}

.logo {
    &__base {
        display: inline-block;
    }

    &__text {
        display: none;
    }
}

.header {
    .usernav {
        &:hover,
        &:active,
        &:focus{
            .usernav__username {
                outline: none;
                text-decoration: underline;
            }

            .usernav__list {
                display: block;
            }
        }
    }
}

.header__logo {
	img {
		transition: transform $transitionTime ease-in-out;
	}

	&:hover,
	&:active,
	&:focus {
		outline: none;

		img {
			transform: scale(1.05);
		}
	}
}

.header__phone {
	width: 195px;
	padding: 0 20px;

	&:hover,
	&:active,
	&:focus {
		.phones__list {
			display: block;
		}
	}

	.phones__list {
		position: absolute;
		top: 100%;
		z-index: 2;
		background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
		box-shadow: 0 18px 20px rgba(0, 0, 0, 0.1);
		border-radius: 0 0 6px 6px;
		display: none;
		margin-left: -30px;
		padding: 30px;
	}

	.phones__item {
		height: 71px;
	}
}

.header__pricelist {
	border: 1px solid $blue;
	border-radius: 30px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 144px;
	height: 40px;

	font-size: 12px;
	line-height: 15px;
	color: $blue;
	text-decoration: none;
	transition: color, background $transitionTime ease-in-out;


	svg {
		margin-right: 13px;

		path {
			transition: fill $transitionTime ease-in-out;
		}
	}

	&:hover,
	&:active,
	&:focus {
		color: #fff;
		background: $blue;

		svg path {
			fill: #fff !important;
		}
	}
}

.header__login {
	font-size: 12px;
	line-height: 15px;
	color: $dark;
	text-decoration: none;

	display: flex;
	align-items: center;
    max-width: 140px;

	img {
		margin-right: 8px;
	}

	&:hover,
	&:active,
	&:focus {
		outline: none;
		text-decoration: underline;
	}
}

.header__service_list {
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;
}

.header__service_item {
	border-right: 1px solid #EBEDF2;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 80px;
		height: 77px;
		background: $bgGray;
		transition: background-color $transitionTime ease-in-out;
		text-decoration: none;
		color: #fff;

		&:hover {
			background: #fff;
		}
	}

	&:last-of-type {
		border: none;
	}


}

.header__count {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	color: #fff;

	font-size: 12px;

	position: absolute;
	top: 21px;
	right: 17px;

	&--blue {
		background: $blue;
	}

	&--red {
		background: $red;
	}

	&--hidden {
		display: none;
	}
}

.header__bottom {
	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}

.header__nav {
	width: 865px;
}

.header__search {

}


@media (max-width: 1200px) {
	.header__top {
		height: 54px;
		border-bottom: none;
	}

	.header__bottom {
		margin-bottom: 20px;
	}

	.header__showDesktopSidenav {
		display: none;
	}

	.header__showSidenav {
		display: block;
	}

	.header__logo {
		margin: 0 30px;
	}

	.header__location {
		display: none;
	}


	.header__login {
		display: none;
	}

	.header__service_list {
		margin-left: 30px;
	}

	.header__service_item {
		border: none;

		a {
			background: none;
			width: 40px;
			height: 40px;
		}
	}

	.header__count {
		top: 5px;
		right: 2px;
	}
}

@media (max-width: 768px) {
    .logo {
        &__base {
            display: none;
        }

        &__text {
            display: inline-block;
            height: auto;
        }
    }

	.header__showSidenav {
		display: flex;
	}

	.header__showDesktopSidenav {
		display: none;
	}

	.header__logo {
		height: auto;
		width: 107px;
		margin-left: 18px;
		margin-right: auto;
	}

	.header__location {
		display: none;
	}


	.header__login {
		display: none;
	}

	.header__pricelist {
		display: none;
	}

	.header__phone {
		display: none;
	}

}

@media (max-width: 480px) {

	.header__logo {
		width: 102px;
		margin-left: 20px;
		margin-right: auto;
	}

	.header__phone {
		display: none;
	}

	.header__pricelist {
		display: none;
	}

	.header__service_list {
		margin-left: 0;
	}
}
