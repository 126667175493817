.content {
	h1:not([class]) {
		@include header_1;
	}

	h2:not([class]) {
		@include header_2;
	}

	h3:not([class]) {
		@include header_3;
	}

	h4:not([class]) {
		@include header_4;
	}

	h5:not([class]) {
		@include header_5;
	}

	hr {
		border-top: 1px solid #EBEDF2;
		margin-top: 55px;
		margin-bottom: 55px;
	}

	p:not([class]) {
		@include text;
	}

    .image {
        text-align: center;
        max-width: 100%;
        padding: 10px;
    }

    .image-style-side {
        float: right;
        max-width: 50%;
    }

    .marked,
    blockquote{
		position: relative;
		padding: 29px 40px;
		margin-top: 73px;
		margin-bottom: 67px;

		&:before {
			content: '';
			height: 60px;
			width: 135px;
			position: absolute;
			top: 0;
			left: 0;
			border-top: 2px solid $blue;
			border-left: 2px solid $blue;
			z-index: -1;
		}

		&:after {
			content: '';
			height: 60px;
			width: 135px;
			position: absolute;
			bottom: 0;
			right: 0;
			border-bottom: 2px solid $blue;
			border-right: 2px solid $blue;
			z-index: -1;
		}

		p:not([class]) {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	ul:not([class]),
	ul.marker,
	ol:not([class]) {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-bottom: 1.5em;

		li {
			font-weight: 400;
			line-height: 31px;
		}
	}

	ul:not([class]) {
		li {
			padding-left: 25px;
			background-image: url("/assets/template/images/svg/check.svg");
			background-repeat: no-repeat;
			background-position: left 9px;
		}
	}

	ul.marker {
		li {
			padding-left: 25px;
			background-image: url("/assets/template/images/svg/circle.svg");
			background-repeat: no-repeat;
			background-position: left 9px;
		}
	}


	ol:not([class]) {
		counter-reset: wslist1;

		li {
			position: relative;
			padding-left: 1.8em;

			&:before {
				content: counter(wslist1);
				counter-increment: wslist1;
				color: $blue;
				position: absolute;
				left: -10px;
				top: 7px;
				width: 22px;
				text-align: center;
				font: 700 14px 'Montserrat';
			}
		}
	}

	.d-flex {
		ul, ol {
			width: 33%;
			margin-top: 67px;
		}
	}


	table:not([class]) {
		width: 100%;
		margin: 68px 0;
		border-collapse: collapse;


		th {
			font-weight: bold;
			padding: 20px;
			background: $bgGray;
		}

		td {
			padding: 11px 20px;
			background: $bgGray;
			border: 1px solid #fff;
			line-height: 27px;

			&:nth-of-type(odd) {
				color: $gray;
			}
		}
	}

	& > a,
	& > p a,
	& > li a{
		color: $blue;

		&:hover,
		&:focus,
		&:visited{
			text-decoration: none;
			color: $purple;
		}
	}
}


@media (max-width:768px) {
	.content {
		h1:not([class]) {
			font-size:36px;
		}

		.d-flex {
			ul, ol {
				width: 100%;
			}
		}
	}
}
