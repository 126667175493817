@mixin orderInputPlaceholder {
    font-weight: 400;
    color: #666666;
}

.order {
    padding: 54px 0px 180px;

    &__main-title {
        display: none;
    }

    &__wrap-form {
        display: flex;
        justify-content: space-between;
    }

    &__inner {
        width: 370px;
    }

    &__item--solid {
        background-color: $bgGray;
        padding: 46px 28px;
        border-radius: 4px;
    }

    &__input,
    &__enter-address,
    &__textarea{
        background-color: $bgGray;
        border-radius: 4px;
        margin-bottom: 20px;
        width: 100%;
        padding: 13px 18px 14px;
        color: $dark;
        font-size: 14px;
        line-height: 150%;
        font-weight: 600;
        border: 1px solid transparent;


        &::-webkit-input-placeholder {
            @include orderInputPlaceholder();
        }

        &::-moz-placeholder {
            @include orderInputPlaceholder();
        }

        &:-moz-placeholder {
            @include orderInputPlaceholder();
        }

        &:-ms-input-placeholder {
            @include orderInputPlaceholder();
        }

        &:hover {
            border: 1px solid $yellow;
        }

        &:active,
        &:focus {
            border: 1px solid $blue;
        }

        &.error {
            border: 1px solid $red;
        }
    }

    &__enter-address,
    &__textarea{
        min-height: 100px;
    }

    &__order-cost {
        position: relative;
        font-size: 14px;

        span {
            position: absolute;
            top: 0;
            right: 0;
            font-weight: 600;
        }
    }

    &__fieldset {
        border: none;
        padding: 4px 0px 20px;
    }

    &__fieldset-pay {
        padding: 4px 0px 0px;
    }

    &__btn {
        margin-top: 32px;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }

    &__custom-radio + label {
        margin-right: 32px;
    }

    &__custom-radio-pay + label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__custom-radio-pay:disabled + label {
        display: none;
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 190%;
        color: $dark;
        margin-bottom: 24px;
    }

    &__result-input {
        background-color: #f7f8fa;
        border-radius: 4px;
        margin-bottom: 20px;
        width: 100%;
        padding: 13px 18px;
        color: #1a1a1a;
        font-size: 14px;
        line-height: 150%;

        &-address {
            min-height: 100px;
        }
    }

    &__result-files {
        padding-left: 20px;

        li {
            list-style: disc inside;
        }
    }

    &__result-cart {
        width: 100%;
        margin-bottom: 20px;

        .cart-products__item {
            align-items: center;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .cart-products__wrap-elems {
            padding-bottom: 0;
        }

        .cart-products__elems-control {
            padding-bottom: 0;
        }
    }
}

.getOrder {
    padding-top: 0;

    &__header {
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 20px;
    }
}

.snapshot {
    margin-bottom: 40px;

    &__list {

    }

    &__item {

    }
}


@media (max-width: 1200px) {
    .order {
        &__inner {
            width: 32%;
        }

        &__custom-radio + label {
            margin-right: 12px;
            margin-bottom: 20px;
        }

        &__fieldset {
            padding: 4px 0 8px;
        }
    }
}

@media (max-width: 980px) {
    .order {
        &__wrap-form {
            flex-direction: column;
        }

        &__order-cost {
            margin-top: 24px;
        }

        &__custom-radio + label {
            margin-right: 16px;
        }

        &__inner {
            width: 100%;
            margin-bottom: 32px;
        }

        &__enter-address {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 728px) {
    .order {
        padding: 54px 0px 60px;

        &__title {
            font-size: 16px;
        }


        &__custom-radio + label,
        &__order-cost,
        &__input,
        &__enter-address {
            font-size: 12px;
        }

        &__item--solid, &__info-order {
            padding: 46px 18px;
        }
    }
}
