.work-example{
    &__title {
        margin-bottom: 60px;

        @media(max-width: 768px) {
            margin-bottom: 24px;
        }
    }

    &__slide{
        height: 275px;
       // opacity: 0.6;

        @media(max-width: 980px) {
            height: 140px;
        }

        @media(max-width: 768px) {
            height: 94px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    //.swiper-slide-prev, .swiper-slide-next, .swiper-slide-active {
    //    opacity: 1;
    //}
}
