$bc-input: #F7F8FA;

.info-order {
    display: block;
    background-color: $bgGray;
    padding: 46px 28px;
    border-radius: 4px;

    span {
        margin-right: 0;
    }

    &__wrap {
        width: 100%;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 18px;
    }

    &__item-total-price {
        span {
            font-weight: 600;
            font-size: 18px;
        }
    }

    &__item-discount {
        span {
            color: #C12E33;
        }
    }

    &__item-promocode {
        display: none;

        &--active {
            display: table-row;
        }
    }

    &__item-bonus {
        color: #1A1A1A;
    }

    &__item--delevery {
        td {
            padding-bottom: 12px;
        }
    }

    &__item-result {
        font-size: 24px;
        line-height: 150%;
        color: #1A1A1A;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;

        span {
            margin-top: 12px;
        }

    }

    &__assent {
        padding-top:16px;
        font-size: 10px;
        line-height: 1.4;

        p {
            margin-left: 4px;
            padding-top: 10px;
        }

        a {
            text-decoration: underline;
            width: 100%;
        }
    }

    &__btn {
        display: block;
        width: 100%;
        background-color: $blue;
        color: #fff;
        font-weight: 500;
        padding: 20px 0;
        border-radius: 4px;
        transition: 0.25s;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background-color: #3e6ce2;
        }

        &:active {
            background-color: #173581;
        }
    }
}

.info-order__item {
    td {
        min-width: 50%;

        span {
            font-weight: 600;
            display: block;
            margin-bottom: 12px;
            text-align: right;
        }
    }
}

@media (max-width: 1200px) {
    .info-order {
        &__item-result {
            font-size: 16px;
        }

        &__item--total-price {
            span {
                font-size: 16px;
            }
        }
    }
}
