.reviews__header {
	display: flex;
	align-items: center;
	justify-content:space-between;
	margin-bottom: 20px;
}

.reviews__total {
	display: flex;
	align-items: center;
}

.reviews__average {
	font-size: 30px;
	font-weight: 700;
	line-height: 57px;
	color:$blue;
	margin-right: 23px;
}

.reviews__average-title {
	font-size: 11px;
	line-height: 21px;
	color:$superGray;
	margin-bottom: 3px;
}


.reviews__button {
	margin-top: 50px;
}

@media (max-width: 480px) {
	.reviews__header {
		flex-wrap: wrap;

		.button {
			width: 100%;
			margin-top: 12px;
		}
	}
}
