.quantity {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 140px;
}

.quantity__button {
	width: 40px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;


	font-size: 20px;
	line-height: 30px;
	text-align: center;

}

.quantity__input {
	width: 60px;
	height: 40px;
	background: #fff;
	text-align: center;
	padding: 14px 0;
	border: 1px solid #EBECF2
}
