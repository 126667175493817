.phones {
	display: flex;
	align-items: center;
	position: relative;


	&:hover,
	&:active,
	&:focus{
		.header__phone-link {
			outline: none;
			text-decoration: underline;
		}
	}
}

.phones__start {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 60px;
}

.phones__link {
	font-weight: 500;
	color: $dark;
	text-decoration: none;
	font-size: 14px;
	line-height: 18px;


	&:hover,
	&:active,
	&:focus {
		outline: none;
		text-decoration: underline;
	}
}

.phones__expand-btn {
	padding: 5px;
}


.phones__expand {
	margin-left:3px;
	padding: 5px;
}

.phones__list {
	list-style: none;
	margin:0;
	padding:0;

	background: #fff;
	width: 100%;
}


.phones__item {

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-bottom: 1px solid #EBEDF2;


	&:hover,
	&:active,
	&:focus{
		a {
			outline: none;
			text-decoration: underline;
		}
	}

	&:last-of-type {
		border:none;
	}

	span {
		color: $superGray;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		display: block;
		margin-bottom: 11px;
	}

	a {
		color: $dark;
		text-decoration: none;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}
}
