.banner {
    position: relative;
    background: linear-gradient(84.03deg, #2E58C1 29.86%, #4E2EC1 101.28%);
    border-radius: 6px;
    color: #fff;

    &__text-wrap {
        padding: 40px 20px 0px;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    &__descr {
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        margin-bottom: 30px;
    }

    &__btn {
        position: relative;
        display: block;
        margin: 0;
        background-color: #FCE885;
        padding: 18px 24px;
        width: 100%;
        border-radius: 6px;
        border: 0;
        font-size: 14px;
        cursor: pointer;
        transition: 0.2s;
        z-index: 1;

        &:hover {
            background-color: #f5d018;
        }

        &:active {
            background-color: #e6cd52;
        }
    }

    &__img {
        position: relative;
        display: block;
        width: 100%;
        padding: 80px 0;
        overflow: hidden;
    }

    img {
        position: absolute;
    }

    &__img-bg {
        z-index: 1;
        top: 0;
        right: 0;
        margin-right: -30%;
        width: 120%;
        height: 140%;
    }

    &__img-product {
        z-index: 2;
        top: 10px;
        right: 0;
        margin-right: -20px;
        width: 90%;
        height: 90%;
        object-fit: contain;
    }

    #banner-arrow {
        display: none;
    }
}

@media screen and (max-width: 980px) {
    .banner {
        padding-bottom: 24px;

        &__img {
            position: absolute;
            top: 10%;
            right: 0px;
            width: 160px;
            height: 160px;
            padding: 0px 0px;
        }

        &__img-bg {
            margin-right: -20px;
            height: 100%;
            width: 100%;
        }

        &__img-product {
            margin-right: -10px;
            width: 80%;
            height: 80%;
        }

        &__btn {
            text-align: center;
        }
    }
}

@media screen and (max-width: 728px) {
    .banner {
        border-radius: 0;
        margin: 0 -10px;
        overflow: hidden;

        &__text-wrap {
            width: 50%;
        }

        &__img {
            width: 190px;
            height: 190px;
        }

        &__img-bg {
            margin-right: -70px;
        }

        #banner-arrow {
            display: block;
            position: absolute;
            width: 50%;
            height: 30%;
            bottom: -7%;
            right: -10%;
        }

        &__text-wrap {
            width: 80%;
            margin-bottom: 0px;
        }

        &__btn {
            width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .banner {
        &__img {
            top: 10%;
            width: 160px;
            height: 160px;
        }

        #banner-arrow {
            bottom: -12%;
        }
    }
}
