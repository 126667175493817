.brands {
    position: relative;

    @include indexBtn();

    padding-bottom: 80px;
    margin-top: -60px;
    z-index: 1;

    .container {
        position: relative;
    }

    @media(max-width: 768px) {
        padding-bottom: 40px;
    }

    &__arrows {
        top: 0;
    }

    &__slide {
        height: 60px;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            transition: 0.2s;
            filter: saturate(0);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media(max-width: 768px) {
            padding: 0 14px;
        }

      &:hover {
          img{
              filter: saturate(1);
          }
      }
    }

    &__link {
        display: inline-block;
    }
}
