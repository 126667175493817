.pagination {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0 14px;

    .page-item {
        display: inline-block;
        height: 37px;
        margin-bottom: 10px;

        a {
            color:$dark;
            background-color: #fff;
            padding: 10px 20px;
            margin-right: 6px;
            border-radius: 6px;
            border: 1px solid #EBEDF2;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            transition: 0.2s;

            &:hover {
                background-color: #eff3ff;
            }
        }
    }

    .active {
        a {
            background-color: #EBEDF2;
        }
    }
}
