#slider-products {
    // margin-bottom: -120px;
}
#slider-products .products-list {
  
}

#slider-products .products-list ul {
    flex-wrap: nowrap;
    padding:0px 20px 340px 20px;
    margin: 0 -20px -120px;
    z-index: 100;
}

#slider-products {
    .product-item {
        margin: 0;
    }
}