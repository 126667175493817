.review {
	padding: 25px 0;
	border-bottom: 1px solid #E4E6EB;
	display: flex;
	align-items: flex-start;
}

.review__avatar {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #EBECF2;
	color: #fff;

	font-size: 30px;
	font-weight: 700;
	line-height: 57px;

	margin-right: 28px;

	display: flex;
	align-items: center;
	justify-content: center;

}


.review__content {
	width: calc(100% - 60px - 28px);
}

.review__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.review__name {
	font-weight: 700;
	line-height: 27px;
}

.review__info {
	display: flex;
	align-items: center;
}

.review__date {
	font-size: 12px;
	line-height: 23px;
	text-align: right;
	margin-right: 30px;
}

.review__text {
	line-height: 27px;
}

.review__images-title {
	font-size: 12px;
	line-height: 23px;
	color: $superGray;
}

.review__image-link {
	margin-right: 10px;
}

.review__form-addPhoto {
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F7F8FA;
	border-radius: 6px;
	margin-top: 15px;
	border: 1px solid transparent;
	margin-bottom: 10px;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $gray;
	}
}

@media (max-width: 480px) {
	.review {
		padding-top: 50px;
	}

	.review__avatar {
		font-size: 18px;
		width: 36px;
		height: 36px;
	}

	.review__content {
		width: calc(100% - 36px - 14px);
	}

	.review__header {
		flex-wrap: wrap;
		margin-top: -25px;
	}

	.review__info {
		order:0;
		width: 100%;
		margin-bottom: 5px;
	}

	.review__name {
		order:1;
		width: 100%;
	}
}
