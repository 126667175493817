.compare {
    margin-bottom: 50px;
    margin-top: 50px;

    &__top {
        background: #f7f8fa;
        width: 1170px;
        padding: 0 15px;
        display: flex;
        position: sticky;
        top: 0;
        z-index:1;
    }

    &__header {
        width: 240px;
        flex-basis: 240px;
        flex-shrink: 0;
        font-weight: 700;
        font-size: 16px;
        margin-left: -15px;
        padding: 15px 0 0 15px;
    }

    &__products {
        position: relative;
        top: 0;
        left: 0;
        overflow-x: hidden;
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__arrow {
        width: 60px;
        height: 60px;
        position: absolute;
        top: calc(50% - 30px);
        z-index: 1;
        box-shadow: 1px 1px 3px 5px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
        }
    }

    &__arrow-right {
        right: 5px;
    }

    &__arrow-left {
        left: 255px;
        transform: rotate(180deg);
    }

    &__bottom {
        margin-top: 20px;
    }

    &__text {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 1200px) {
    .compare {
        &__top {
            width: 960px;
        }
    }
}

@media (max-width: 980px) {
    .compare {
        &__top {
            width: 720px;
        }

        &__header {
            width: 200px;
            padding: 10px;
        }

        &__products {
            left: 215px;
        }

        &__arrow-left {
            left: 215px;
        }
    }
}

@media (max-width: 768px) {
    .compare {
        &__top {
            width: 100%;
        }

        &__header {
            display: none;
        }

        &__products {
            left: 0;
        }

        &__arrow-left {
            left: 0px;
        }
    }
}

