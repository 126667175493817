.newsSection {
    margin-top: 40px;
    margin-bottom: 40px;

    &__section-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 40px;
    }

    &__section-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        margin-right: 12px;
    }

    &__section-link {
        border-radius: 24px;
        padding: 8px 16px;
        background: rgba(181,190,198,.2);
        color: $dark;
        font-size: 16px;
        line-height: 18px;

        &:hover {
            background: $blue;
            color: #fff;
        }

        &--active {
            background: $blue;
            color: #fff;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media (max-width: 480px) {
    .newsSection {
        &__items {
            justify-content: center;
        }
    }
}
