.socials {

}

.socials__list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.socials__item {
	margin-right: 25px;

	&:last-of-type {
		margin-right: 0;
	}

	a {
		display: inline-block;
		padding: 5px;

		&:hover,
		&:focus {
			svg path {
				fill: $blue;
			}
		}
	}
}

