.compareProduct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 3px;
    position: relative;
    transition: left ease-in-out 0.5s;
    left: 0;

    &__content {
        background: #fff;
        margin-right: 15px;
        width: 330px;
    }

    &__link {
        text-decoration: none;
        color: $dark;
        display: flex;
    }

    &__left {
        width: 35%;
    }

    &__image {
        max-width: 100%;
    }

    &__right {
        width: 65%;
        padding: 10px;
        display: flex;
        flex-direction: column;

    }

    &__title {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 20px;
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
    }

    &__button {
        width: 100px;
        padding: 0 15px;
        height: 40px;
        margin-right: 20px;
    }

    &__addtocart {
        svg {
            width: 25px;
        }
    }

    &__price {
        font-weight: 600;
        font-size: 18px;
        color: #1a1a1a;
        margin-bottom: 12px;
    }

    &__old_price {
        font-size: 14px;
        text-decoration-line: line-through;
        margin-right: 14px;

    }

    &__addtocart {
        height: 40px;
        width: 40px;
        z-index: 110;
        border-radius: 6px;
        background-color: #2e58c1;
        transition: .3s;

        button {
            display: block;
            cursor: pointer;
            padding: 0;
            margin: 0;
            background-color: rgba(0, 0, 0, 0);
            border: none;
            width: 100%;
            height: 100%;
        }
    }
}
