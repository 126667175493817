.availability {
	display: flex;
	align-items: center;

	font-size: 12px;
	line-height: 18px;
	margin-bottom: 16px;
	color: $gray;
}

.availability__label {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin-right: 9px;
}


.availability__label--green {
	background: #3E8725;
}

.availability__label--yellow {
	background: #F3A845;
}

.availability__label--red {
	background: $red;
}

.availability__label--gray {
    background: $fontColor;
}

.availability__label--purple {
    background: #8b00ff;
}

@media (max-width: 480px) {
	.availability {
		font-size: 14px;
	}
}
