.section-title {
    font-size: 30px;
    line-height: 150%;
    text-align: center;
    font-weight: bold;
    color: $dark;

    @media(max-width: 768px) {
        font-size: 16px;
        line-height: 150%;
        text-align: left;
    }
}
