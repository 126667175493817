.advanced {
  text-align: center;
  padding: 80px 0;

  @media(max-width: 768px) {
    text-align: left;
    padding: 40px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media(max-width: 768px) {
      flex-direction: column;
    }
  }

  &__item {
    @media(max-width: 1600px) {
      margin: 0 20px;
        width: 100%;
    }

    @media(max-width: 768px) {
      display: flex;
      align-items: center;
      margin: 0 auto 20px;
      min-width: 260px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 16px;

    @media(max-width: 768px) {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  &__desc {
    @media(max-width: 768px) {
      font-size: 12px;
    }
  }

  &__picture {
    width: 92px;
    height: 85px;
    margin-bottom: 12px;

    @media(max-width: 768px) {
      width: 52px;
      height: 52px;
      margin-right: 16px;
    }

    img {
      object-fit: contain;
    }
  }


  &__item:first-child .advanced__picture{
    margin: 0 auto 32px;
    background: no-repeat center/contain url("../images/advanced/advanced_01.svg");

    @media(max-width: 768px) {
      margin: 6px 16px 6px 0;
      background: no-repeat center/contain url("../images/advanced/advanced--mobile_01.svg");
    }
  }

  &__item:nth-child(2) .advanced__picture{
    margin: 12px auto 20px;
    background: no-repeat center/contain url("../images/advanced/advanced_02.svg");

    @media(max-width: 768px) {
      margin: 6px 16px 6px 0;
      background: no-repeat center/contain url("../images/advanced/advanced--mobile_02.svg");
    }
  }

  &__item:nth-child(3) .advanced__picture{
    margin: 0 auto 32px;
    background: no-repeat center/contain url("../images/advanced/advanced_03.svg");

    @media(max-width: 768px) {
      margin: 6px 16px 6px 0;
      background: no-repeat center/contain url("../images/advanced/advanced--mobile_03.svg");
    }
  }

  &__item:nth-child(4) .advanced__picture{
    margin: 12px auto 20px;
    background: no-repeat center/contain url("../images/advanced/advanced_04.svg");

    @media(max-width: 768px) {
      margin: 6px 16px 6px 0;
      background: no-repeat center/contain url("../images/advanced/advanced--mobile_04.svg");
    }
  }


}
