$bc-gray: #f7f8fa;

@mixin reset-ul {
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
        text-decoration: none;
    }
}

.slider__wrap {
    position: relative;
    list-style: none;
    margin: 0 -10px;
    padding: 0;
}

.catalog {
    position: relative;

    &__main-title {
        display: none;
    }

    &__wrap {
        padding-top: 54px;
        margin-bottom: 80px;
        display: flex;
        justify-content: space-between;
    }

    &__products-card {
        min-width: 970px;
    }

    &__info {
        min-width: 210px;
        margin-right: 30px;
    }
}

.categories-mobile {
    display: none;
    margin: 10px 12px 0px;

    &__list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding: 12px 0px;
    }

    &__item {
        margin-right: 12px;
        flex: 0 0 auto;
        font-style: normal;
        font-size: 11px;
    }

    a {
        color: #666666;
        background-color: $bc-gray;
        padding: 12px 24px;
        border-radius: 6px;
        line-height: 300%;
    }
}

.item-active {
    a {
        color: #1a1a1a;
        font-weight: 700;
    }
}

// products
.products-list {
    position: relative;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;

        li a {
            text-decoration: none;
            color: #1a1a1a;
        }
    }
}

@media screen and (max-width: 1200px) {
    .catalog {
        &__products-card {
            min-width: 70%;
        }

        .products-card {
            &__item {
                margin: 10px;
                width: calc(50% - 20px);
            }

            &__bg {
                position: relative;
                width: calc(100%);
                padding: 0px;
                margin: 0;
            }
        }

        &__info {
            min-width: calc(30% - 30px);
        }
    }
}

@media screen and (max-width: 980px) {
    .categories-mobile {
        display: block;
    }

    .catalog {
        &__wrap {
            padding-top: 0;
            flex-direction: column;
        }

        &__info {
            order: 1;
            margin-top: 40px;
            width: 100%;
        }

        &__products-card {
            width: 100%;
        }
    }

    .categories {
        display: none;
    }
}

@media screen and (max-width: 728px) {
    .catalog {
        &__wrap {
            margin-bottom: 32px;
        }
    }

    .catalog__products-card .products-card__item {
        margin: 5px;
        width: calc(50% - 10px);
    }
}
