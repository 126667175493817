.products {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
}

.products__carousel-wrapper {
	overflow: hidden;
	//padding:0 20px;
	//margin-left: 30px;
	//width: calc(100% + 30px);
	padding: 0 15px;


	h2 {
		margin-left:30px;
	}

	.products__carousel{
		z-index:3;
		overflow: visible;
		height: 550px;
		padding: 5px 0 30px 0;
	}

	//.products__item {
	//	margin:0 5px;
	//}
}
