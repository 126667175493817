@mixin header_1 {
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 66px;
    margin-top: 0;
    margin-bottom: 1em;
    color: $dark;
}

@mixin header_2 {
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 150%;
    color: $dark;
    margin-top: 0;
    margin-bottom: 1em;
}

@mixin header_3 {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    color: $dark;
    margin-top: 0;
    margin-bottom: 1em;
}

@mixin header_4 {
    font-family: $font;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: $dark;
    margin-top: 0;
    margin-bottom: 1em;
}

@mixin header_5 {
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 300%;
    color: $dark;
    margin-top: 0;
    margin-bottom: 1em;
}

@mixin text {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: $gray;
    margin-top: 0;
    margin-bottom: 2.5em;
}

@mixin text_2 {
    font-family: $font;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: $gray;
    margin-top: 0;
    margin-bottom: 2.5em;
}

@mixin indexBtn {
    &__arrows {
        position: absolute;
        //z-index: 100;
        z-index:1;
        top: 50%;
        left: -60px;
        width: calc(100% + 120px);
        transform: translateY(-50%);

        @media(max-width: 1400px) {
            display: none;
        }
    }

    &__arrow {
        cursor: pointer;
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0;
        transition: 0.2s;
        opacity: 0.6;
        border-radius: 50%;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            opacity: 1;
            box-shadow: 0 0 10px rgba(7, 14, 32, 0.05);
        }

        &--prev {
            left: 0;
        }

        &--next {
            transform: scale(-1, 1);
            right: 0;
        }
    }
}
