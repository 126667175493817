.gotoTop {
	position: fixed;
	z-index: 10;
	bottom: 160px;
	right: 275px;
	display: none;
	width: 60px;
	height: 60px;
	background: none;
	background: $blue;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	transition-property: background;
	transition-duration: $transitionTime;
	transition-timing-function: ease-out;

	&:hover {
		background-color: #4E2EC1;
	}

	&.show {
        display: flex;
	}
}

@media (max-width: 1200px) {
	.gotoTop {
		right: 10px;
		width: 40px;
		height: 40px;

		img {
			height: 20px;
		}
	}
}
