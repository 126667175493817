$linkColor: #B7BEE9;

.crumbs {
    background: linear-gradient(105.61deg, #2E58C1 3.48%, #4E2EC1 87.75%);
    overflow: hidden;
    padding-top: 28px;

    .container {
        position: relative;
        padding-top: 10px;
        padding-bottom: 60px;
    }

    &__desc {
        position: relative;
        max-width: 560px;
        color: $whiteText;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 62px;
        z-index: 3;

        @media(max-width: 1200px) {
            max-width: 80%;
        }

        @media(max-width: 768px) {
            font-size: 10px;
            margin-bottom: 24px;
        }
    }

    &__bg-image {
        position: absolute;
        width: 520px;
        height: 226px;
        top: -28px;
        right: 0;
        z-index: 0;
        mix-blend-mode: multiply;

        img {
            width: 100%;
            object-fit: cover;
            object-position: left;
        }
    }

    &__picture {
        position: absolute;
        z-index: 2;
        background: no-repeat center/contain url("../images/crumbs/crumbs_pic.png");
        width: 70%;
        height: 100%;
        top: 0;
        right: -15%;

        @media(max-width: 1200px) {
            max-width: 80%;
            right: -30%;
        }

        @media(max-width: 768px) {
            height: 80%;
            top: auto;
            width: 80%;
            right: -40%;
            bottom: 0;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        z-index: 1;
    }

    &__item {
        margin-right: 5px;
        margin-bottom: 5px;
        opacity: 0.8;
    }

    &__link {
        font-size: 11px;
        line-height: 17px;
        color: $linkColor;
        text-decoration: none;
    }

    &__separator {
        font-size: 11px;
        line-height: 17px;
        color: $linkColor;
    }

    &__current {
        font-size: 11px;
        line-height: 17px;
        color: #fff;
    }

    &__header {
        font-size: 48px;
        font-weight: 700;
        line-height: 59px;
        color: #fff;
        margin-bottom: 17px;
        margin-top: 29px;
        position: relative;
        z-index: 1;
    }

    &__header-shadow {
        font-size: 94px;
        font-weight: 700;
        line-height: 115px;
        white-space: nowrap;
        opacity: 0.05;
        position: absolute;
        top: -23px;
        left: -15px;
    }


    @media (max-width: 980px) {
        &__bg-image {
            right: -26%;
        }
    }

    @media (max-width: 768px) {
        padding-top: 0;
        .container {
            padding-top: 54px;
            padding-bottom: 40px;
        }

        &__bg-image {
            width: 465px;
            height: 173px;
            right: -16%;
        }


        &__header {
            margin-bottom: 27px;
            margin-top: 0;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
        }

        &__header-shadow {
            font-size: 42px;
            line-height: 51px;
            font-weight: 700;
            opacity: 0.05;
            position: absolute;
            top: 30px;
            left: -10px;
        }
    }

    @media (max-width: 576px) {
        &__bg-image {
            display: none;
        }

        &__desc {
            font-size: 14px;
            max-width: 100%;
        }
    }
}

