.tools {
    border-bottom: 1px solid #EBEDF2;
    font-size: 14px;

    &:hover {
        background-color: $bc-gray;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    &__right {
        display: flex;
        align-items: center;
        line-height: 150%;
        min-height: 76px;
    }



    &__filter {
        display: none;
        padding: 30px 20px;
        cursor: pointer;
        margin: 0 20px 0 40px;
        transition: 0.2s;
        font-size:14px;

        span {
            margin-right: 12px;
        }
    }
}

@media screen and (max-width: 960px) {
    .tools {
        background-color: #F7F8FA;

        &__left {
            display: none;
        }

        &__right {
            background-color: #F7F8FA;
            width: 100%;
            justify-content: space-between;
            min-height: 56px;
        }


        &__filter {
            display: block;
            margin: 0;
            padding: 20px 10px 20px 20px;
            margin-right: -10px;
            background-color: $bc-gray;

            &:hover {
                background-color: #fff;
            }
        }
    }
}
