.orders {
    margin-top: 40px;
    margin-bottom: 40px;
}

.orders__header {
    display: flex;
    border-bottom: 1px solid #ebedf2;
    padding-bottom: 20px;
    margin-bottom: 20px;

    span {
        width: 25%;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 0;
    }
}

.orders__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #ebedf2;
    padding-bottom: 20px;
    margin-bottom: 20px;
    transition: .3s;

    &:last-of-type {
        border: none;
    }
}

.orders__num,
.orders__date,
.orders__cost,
.orders__status {
    width: 25%;
    padding: 10px 0;
}

.orders__status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.orders__status-name {
    margin-bottom: 10px;
    padding: 4px 10px;
    border-radius: 3px;
    background-color: #cacaca;
    color: #000;
    font-weight: 700;
}

// новый
.orders__status-1 {
    background: $blue;
    color: #fff;
}

// Ожидает оплаты
.orders__status-6,
// Отгружается
.orders__status-2{
    background: $yellow;
    color: #000;
}
// Отгружен
.orders__status-3{
    background: green;
    color: #fff;
}

// Отменен
.orders__status-4 {
    background: #000;
    color: #fff;
}

.orders__status-date {
    font-size: 12px;
}

.orders__status-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.orders__tabs {
    display: flex;
    margin-bottom: 20px;
}

.orders__tab {
    font-size: 16px;
    font-weight: 700;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid #ebedf2;
    border-bottom: 1px solid #ebedf2;
    background: #f7f8fa;

    &:last-of-type {
        border: none;
        border-radius: 0 10px 10px  0;
    }

    &:first-of-type {
        border-radius:  10px 0  0 10px;
    }

    &:hover {
        background: #4e2ec1;
        color: #fff;
    }

    span {
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #000;
        margin-top: -10px;
        margin-left: 5px;
        border-radius: 50%;
        width: 20px;
        height: 20px;

    }

    &--active {
        background: #2e58c1;
        color: #fff;
        border-right: 1px solid #2e58c1;

        span {
            color: #000;
            background: #fff;
        }
    }
}

.orders__mobile-title {
    display: none;
}

@media (max-width: 768px) {
    .orders__tabs {
        flex-wrap: wrap;
    }

    .orders__tab {
        width: 100%;

        &:last-of-type {
            border: none;
            border-radius: 0 0 10px 10px;
        }

        &:first-of-type {
            border-radius: 10px 10px 0 0;
        }
    }

    .orders__header {
        display: none;
    }

    .orders__num,
    .orders__date,
    .orders__cost,
    .orders__status {
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
    }

    .orders__mobile-title {
        display: block;
        width: 50%;
        font-weight: 700;
    }

    .orders__status-info {
        display: flex;
        width: 50%;
        flex-direction: column;
        align-items: flex-start;
    }
}
