.location {
    position: relative;
    background: $bgGray;
    border-radius: 30px;
    height: 40px;
    width: 144px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;

    transition: background-color $transitionTime ease-in-out;

    &:hover {
        background: darken($bgGray, 5%);
    }
}

.location__selected {
    font-size: 12px;
    line-height: 15px;
}

.location__list {
    margin:20px 0;
}

.location__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    &--active {
        color: $red;

        .location__link {
            color: $red;
        }
    }

    &:hover {
        background: $bgGray;
    }
}

.location__link {
    color:$dark;
    display: inline-block;
    padding:10px 0;
}

.suggestions {
    margin: 20px 0;

    &__item {
        &:hover {
            background: $bgGray;
        }
    }

    .suggestions__item {
        padding: 10px 20px;
        margin-bottom: 5px;

        &:hover {
            background: $bgGray;
            cursor: pointer;

        }
    }
}
