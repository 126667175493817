.about {
  padding: 125px 0 0;
  background-color: #F7F8FA;

  .container {
    position: relative;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 90px;

    @media(max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  @media(max-width: 768px) {
    padding: 50px 0 0;
  }

  &__wrapper {
    max-width: 750px;

    @media(max-width: 1200px) {
      max-width: calc(100% - 270px);
    }

    @media(max-width: 980px) {
      max-width: 100%;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 150%;
    margin-bottom: 46px;

    span {
      font-weight: bold;
      font-size: 30px;
      line-height: 150%;
    }

    @media(max-width: 768px) {
      font-size: 16px;
      margin-bottom: 16px;

      span {
        font-size: 16px;
      }
    }
  }

  &__wrap-text {
    position: relative;
    font-size: 16px;
    line-height: 190%;

    p {
      margin-bottom: 40px;
        font-size: 16px;

      @media(max-width: 768px) {
        margin-bottom: 16px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -60px;
      right: 0;
      width: 55px;
      height: 50px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 55 51'%3e%3cpath fill='%232E58C1' d='M41.2 21.3c-2.267-.667-4-1.933-5.2-3.8-1.333-1.733-2-3.867-2-6.4 0-3.2 1-5.8 3-7.8s4.467-3 7.4-3c3.2 0 5.8 1 7.8 3 1.867 2.133 2.8 4.733 2.8 7.8 0 1.6-.133 3.133-.4 4.6-.4 1.6-1.133 3.867-2.2 6.8L44 50.1h-9.6l6.8-28.8zm-34 0c-2.267-.667-4-1.933-5.2-3.8-1.333-1.733-2-3.867-2-6.4 0-3.2 1-5.8 3-7.8s4.467-3 7.4-3c3.2 0 5.8 1 7.8 3 1.867 2.133 2.8 4.733 2.8 7.8 0 1.6-.133 3.133-.4 4.6-.4 1.6-1.133 3.867-2.2 6.8L10 50.1H.4l6.8-28.8z' opacity='.1'/%3e%3c/svg%3e");
      background-position: 100%;
      background-repeat: no-repeat;

      @media(max-width: 768px) {
        width: 38px;
        height: 34px;
        bottom: -50px;
      }
    }

    @media(max-width: 768px) {
      font-size: 10px;
      line-height: 190%;
    }
  }

  &__photo {
    position: absolute;
    top: 0;
    right: 0;
    width: 270px;
    height: 270px;

    @media(max-width: 980px) {
      width: 70px;
      height: 70px;
      top: auto;
      bottom: 85px;
      right: auto;
      left: 15px;
    }

    @media(max-width: 768px) {
      bottom: 34px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

  }

  &__author {
    font-size: 16px;
    line-height: 190%;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 190%;
    }

    @media(max-width: 980px) {
      padding-left: 85px;

      span {
        display: block;
      }
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 190%;
      padding-top: 20px;

      span {
        font-size: 14px;
      }
    }

  }

  &__armeks {
    display: inline-block;
    width: 165px;
    height: 20px;

    @media(max-width: 768px) {
      width: 80px;
      height: 10px;
      margin-top: -1px;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

}
