.crumbs__solution-image {
    position: absolute;
    left: 59%;
    top: 0;
    max-height: 100%;
    height: 100%;
    mix-blend-mode: multiply;

    img {
        width: 100%;
       // height: 100%;
        object-fit: cover;
        object-position: left;
    }
}


.solutionPage {
    &__crumbs {
        position: relative;
        overflow: hidden;
    }

    &__content {
        background: #f7f8fa;
        margin-bottom: 30px;
        margin-top: 30px;
        width: 100%;
        padding: 50px;
    }
}

@media  (max-width: 1600px) {
    .crumbs__solution-image {
        img {
            height: 100%;
        }
    }
}

@media  (max-width: 1200px) {
    .crumbs__solution-image {
        display: none;
    }
}

