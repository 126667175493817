.auth__button {
    width: 100%;
    margin-top: 20px;
    position: relative;

    &:disabled {
        filter: grayscale(1);
        cursor: no-drop;
    }
}

.auth__error {
    padding: 15px;
    margin-top: 15px;
    border-radius: 3px;
    background: #f5c6cb;
    color: #721c24;
}

.auth__link {
    display: block;
    margin-top: 25px;
}

.register__type {
    font-size: 22px;
    width: 48%;
    margin-right: 2%;
    justify-content: center;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
}

.register__type--active {
    border-bottom: 2px solid #c12e33;
}
