.params {
    margin-bottom: 60px;

    @media(max-width: 768px) {
        margin-bottom: 0;
    }

    &__header{
        display: flex;
        margin-bottom: 16px;

        @media(max-width: 980px) {
            display: none;
        }
    }

    &__caption{
        font-size: 11px;
        line-height: 300%;
        color: #666666;

        &:nth-child(2) {
            margin-left: 440px;

            @media(max-width: 1200px) {
                margin-left: 330px;
            }
        }

        &:last-child {
            margin-left: 235px;

            @media(max-width: 1200px) {
                margin-left: 135px;
            }
        }
    }

    &__wrap {
        position: relative;
        display: flex;
        border-radius: 6px;
        background-color: #fff;
        margin-bottom: 10px;


        @media(max-width: 768px) {
            flex-wrap: wrap;
            padding-left: 80px;
        }
    }

    &__ico {
        min-height: 100%;
        width: 100px;
        border-radius: 6px 0 0 6px;
        background-color: $blue;
        position: relative;

        @media(max-width: 768px) {
            position: absolute;
            width: 60px;
            top: 0;
            left: 0;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media(max-width: 768px) {
                width: 80%;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 150%;

        @media(max-width: 768px) {
            margin-left: 0;
            margin-bottom: 20px;
            width: 100%;
        }

        &-btn {
            display: block;
            text-align: center;
            width: 40px;
            min-height: 40px;
            border: 1px solid transparent;
            transition: 0.2s;
            border-radius: 4px;

            &:hover {
                border: 1px solid #EBECF2;
            }

            &:active {
                border: 1px solid #0000ee;
            }
        }

        input {
            display: inline-block;
            text-align: center;
            width: 60px;
            padding: 10px 0;
            border: 1px solid #EBECF2;
            box-sizing: border-box;
            border-radius: 3px;

            &:hover {
                border: 1px solid #2e58c1;
            }

            &:active {
                border: 1px solid #0000ee;
            }
        }
    }

    &__title {
        margin-left: 30px;
        width: 390px;
        padding: 24px 0 30px;
        span {
            font-weight: bold;
            font-size: 14px;
            line-height: 300%;
        }

        @media(max-width: 1200px) {
            width: 280px;
        }

        @media(max-width: 980px) {
            width: 180px;
        }

        @media(max-width: 768px) {
            margin-left: 0;
            width: 100%;
            padding: 24px 0;
        }
    }

    &__price {
        margin-left: 200px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;

        @media(max-width: 1200px) {
            margin-left: 100px;
        }

        @media(max-width: 980px) {
            margin-left: 30px;
        }

        @media(max-width: 768px) {
            margin-left: 0;
            margin-bottom: 20px;
            width: 100%;
        }
    }

    &__options--sub {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 40px;

        .options__item {
            display: block;
            margin-bottom: 10px;

            @media(max-width: 768px) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media(max-width: 768px) {
            margin: 0;
            margin-bottom: 20px;
        }
    }
}
