.newsPage {
    display: flex;
    flex-wrap: wrap;

    &__content {
        width: 70%;
    }

    &__sidenav {
        width: 30%;
        padding-top: 60px;
        padding-left: 10px;
        margin-bottom: 40px;
    }

    &__sidenav-header {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        border-bottom: 1px solid #ebedf2;
        padding-bottom: 20px;

        &-link {
            font-weight: 700;
            font-size: 14px;
            color: $dark;
            line-height: 150%;
            margin-bottom: 10px;
        }

        &-date {
            font-size: 12px;
        }

        &-tag {
            border-radius: 4px;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 10px;
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 4px;
            background: $blue;
        }

        &:hover {
            border-bottom: 1px solid #aa98e7;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

@media (max-width: 768px) {
    .newsPage {
        &__content {
            width: 100%;
        }

        &__sidenav {
            width: 100%;
            padding-top: 0;
            padding-left: 0;
            margin-bottom: 40px;
        }
    }
}
