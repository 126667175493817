.footer {
	background-color: #EBECF2;
	position: relative;
	min-height: 522px;
	overflow: hidden;
}

.footer__figure {
	position: absolute;
	border-radius: 50%;
	background: $blue;
	opacity: 0.05;
}

.footer__figure--one {
	width: 538px;
	height: 538px;
	left: -271px;
	top: -291px;
	z-index: 1;
	transform: rotate(105deg);

}

.footer__figure--two {
	width: 479px;
	height: 479px;
	left: -262px;
	top: -288px;
	transform: rotate(105deg);
	z-index: 2;
}

.footer__figure--three {
	width: 538px;
	height: 538px;
	right: -12px;
	bottom: -147px;
	z-index: 1;
}

.footer__figure--four {
	width: 479px;
	height: 479px;
	right: 43px;
	bottom: -153px;
	z-index: 2;
}

.footer__bottom {
	background: #E4E5EB;
	position: absolute;
	z-index: 3;

	bottom: 0;
	width: 100%;

	.container {
		display: flex;
		align-items: center;
		height: 60px;
	}
}

.footer__copyright {
	margin: 0;
	line-height: 21px;
}

.footer__top {
	padding-top: 84px;
	padding-bottom: 100px;

	.container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.footer__left {
	width: 500px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.footer__logo {
	margin-bottom: 56px;
}

.footer__phone {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	color: $dark;
	text-decoration: none;
	margin-bottom: 8px;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.footer__phone_description {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 34px;
}

.footer__callback {
	width: 320px;
	height: 80px;

	display: flex;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: -36px;
		right: -88px;
		width: 118px;
		height: 81px;
		background: url("/assets/template/images/button_arrow.png") no-repeat center center / 100%;
	}
}

.footer__callback-button {
	line-height: 21px;
	width: 320px;
	height: 80px;
}

.footer__right {
	width: calc(100% - 500px);
}

.footer__right-top {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 146px;
}

.footer__nav {
	margin-right: 110px;

	&:last-of-type {
		margin-right: 71px;
	}
}

.footer__nav-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.footer__nav-link {
	line-height: 28px;
	color: $dark;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.footer__address-title {
	font-weight: 600;
	line-height: 21px;
	margin-top: 0;
}

.footer__address-text {
	line-height: 21px;
}

.footer__right-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer__socials {
	margin-right: 50px;
}

.footer__email {
	color: $dark;

	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	text-align: right;

	&:hover,
	&:focus {
		text-decoration: none;
	}

}
@media (max-width: 1200px) {
	.footer__figure {
		display: none;
	}

	.footer__left {
		width: 50%;
	}

	.footer__callback:after {
		display: none;
	}

	.footer__right {
		width: 50%;
	}

	.footer__right-top {
		flex-wrap: wrap;
	}

	.footer__nav {
		width: 50%;
		margin-right: 0;

		&:last-of-type {
			margin-right: 0;
		}
	}

	.footer__address {
		margin-top: 20px;
	}
}

@media (max-width: 768px) {
	.footer__top .container {
		flex-wrap: wrap;
	}

	.footer__left {
		width: 100%;
		margin-bottom: 48px;
	}

	.footer__right {
		width: 100%;
	}
}

@media (max-width: 480px) {

	.footer__top {
		padding-top: 47px;
		padding-bottom: 60px;

		.container {
			flex-wrap: wrap;
		}
	}

	.footer__left {
		width: 100%;
		align-items: center;
		margin-bottom: 48px;
	}

	.footer__logo {
		width: 145px;
		height: auto;
		margin-bottom: 34px;
	}

	.footer__phone {
		font-size: 14px;
		line-height: 20px;
		text-align: center;
	}

	.footer__phone_description {
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		margin-bottom: 26px;
	}

	.footer__callback {
		width: 235px;
		height: 50px;

		&:after {
			top: -53px;
			right: -105px;
		}
	}

	.footer__callback-button {
		width: 235px;
		height: 50px;

		font-size: 11px;
		font-weight: 500;
		line-height: 17px;
		text-align: center;
	}


	.footer__right {
		width: 100%;
	}

	.footer__right-top {
		flex-wrap: wrap;
		margin-bottom: 56px;
	}

	.footer__nav {
		width: 50%;
		margin-right: 0;
		margin-bottom: 43px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	.footer__nav-link {
		font-size: 14px;
		line-height: 29px;
	}

	.footer__address-title {
		font-size: 14px;
		line-height: 20px;
	}

	.footer__address-text {
		font-size: 14px;
		line-height: 20px;
	}

	.footer__right-bottom {
		justify-content: space-between;
		margin-bottom: 59px;
	}

	.footer__email {
		font-size: 14px;
		line-height: 18px;
	}

	.footer__bottom {
		.container {
			justify-content: center;
		}
	}

	.footer__copyright {
		font-size: 11px;
		line-height: 14px;
	}
}
