.packs {
    padding: 120px 0 130px;

    @media(max-width: 768px) {
        padding: 60px 0 65px;
    }

    &__title {
        margin-bottom: 64px;

        @media(max-width: 768px) {
            margin-bottom: 34px;
            text-align: center;
        }
    }
}

.pack {
    background: #F7F8FA;
    border-radius: 6px;
    padding: 44px 40px 84px;
    display: flex;
    margin-bottom: 30px;

    @media(max-width: 768px) {
        flex-direction: column;
        padding: 14px 14px 30px;
    }

    &__picture {
        width: 368px;
        height: 368px;
        flex-shrink: 0;
        margin-right: 40px;
        border-radius: 4px;
        // background-color: #fff;

        @media(max-width: 980px) {
            width: 252px;
            height: 252px;
        }

        @media(max-width: 768px) {
            width: 100%;
            margin-bottom: 22px;
        }

        img {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 22px;

        @media(max-width: 768px) {
            font-size: 14px;
            margin-bottom: 8px;
        }
    }

    &__desc {
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            margin-bottom: 22px;

            @media(max-width: 768px) {
                font-size: 12px;
                line-height: 150%;
                margin-bottom: 8px;
            }
        }
    }

    &__products {
        padding-bottom: 32px;
        border-bottom: 1px solid #E4E6EB;

        @media(max-width: 768px) {
            padding-bottom: 12px;
        }
    }

    &__product {
        padding-left: 20px;
        position: relative;
        margin-bottom: 12px;
        font-size: 12px;

        @media(max-width: 768px) {
            font-size: 10px;
            margin-bottom: 8px;
        }

        &:before {
            display: block;
            position: absolute;
            top: 2px;
            left: 4px;
            content: "";
            width: 8px;
            height: 8px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'%3e%3cpath fill='%232E58C1' d='M3.063 6.989a.407.407 0 01-.577 0L.18 4.682a.612.612 0 010-.866l.288-.29c.24-.238.627-.238.867 0l1.44 1.44 3.89-3.89c.24-.24.628-.24.867 0l.288.289c.24.24.24.627 0 .866L3.063 6.99z'/%3e%3c/svg%3e");
        }
    }

    &__cart {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 1200px) {
            flex-wrap: wrap;
        }

        @media(max-width: 768px) {
            padding-top: 16px;
        }
    }

    &__price {
        font-size: 12px;
        line-height: 160%;
        min-width: 255px;

        @media(max-width: 1200px) {
            min-width: auto;
            font-size: 10px;
        }

        span {
            line-height: 170%;
            display: block;
            font-weight: 600;
            font-size: 22px;

            @media(max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    &__btn-cart {
        @media(max-width: 1200px) {
            width: 100%;
            order: 2;

            .button {
                margin-top: 18px;
                width: 100%;
            }
        }
    }

    &__button {
        margin: 10px 0;
    }

    &__warning {
        margin: 30px 0 10px 0;
        font-weight: 700;
    }
}
