.products-slider{
    .container {
        position: relative;
    }

    .swiper-button-disabled {
        opacity: 0.4;
    }


    &--index {
        margin-top: 80px;

        @media(max-width: 768px) {
            margin-top: 50px;
        }

        .products-slider__main-title {
            text-align: center;
        }

        .products-card__wrap {
            z-index: 3;
        }

        .products-slider__wrap {
            margin: -30px -30px -200px -30px;
            padding: 30px 30px 300px 30px;
            z-index: 10;

            @media(max-width: 1200px) {
                margin: -30px 0 -200px 0;
            }

            @media(max-width: 768px) {
                padding: 0 0 200px 0;
            }
        }
    }
}

.sliders-wrapper {
    .products-slider__wrap, .swiper-container {
        position: static;
        overflow: hidden;
    }
}

.sliders-wrapper--static {
    @media(max-width: 1200px) {
        position: relative;
    }
}

.products-slider__wrap {
    position: relative;
    z-index: 3;
    padding: 30px 30px 300px 30px;
    margin: -30px -30px -150px -30px;
}

.products-slider__arrows {
    position: absolute;
    z-index: 10;
    width: 32px;
    height: 32px;
    cursor: pointer;
    top: 24%;

    svg {
        transition: 0.25s;
        border-radius: 50%;
        &:hover {
            box-shadow: 0px 0px 10px rgba(7, 14, 32, 0.05);
        }
    }
}

.products-slider__arrows-prev {
    left: -7.1%;
    svg {
        transform: rotate(180deg);
    }
}

.products-slider__arrows-next {
    right: -5%;
}

.products-slider__main-title {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 52px;
    color: $dark;
}

.products-slider__products-card {
    flex-wrap: nowrap;

    .products-card__item {
        margin: 0;
    }

    .products-card__item:hover {
        .products-card__bg {
            box-shadow: 0px 5px 15px rgba(7, 14, 32, 0.08);
        }
    }
}

@media (max-width: 1400px) {
    .products-slider__arrows {
        display: none;
    }
}

@media (max-width: 1200px) {
    .products-slider__products-card {
        .products-card__item:hover {
            .products-card__bg {
                box-shadow: none;
            }
        }
    }

    .products-slider__wrap {
        padding: 0px;
        margin: 0px;
        margin-bottom: 120px;
    }
}

@media (max-width: 980px) {
    .products-slider__main-title {
        font-size: 22px;
    }
}

@media (max-width: 728px) {
    .products-slider__main-title {
        font-size: 18px;
    }

    .products-slider__wrap {
        margin-bottom: 52px;
    }
}
