.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal__container {
	background-color: #fff;
	max-width: 563px;
	max-height: 100vh;
	border-radius: 6px;
	overflow-y: auto;
	padding: 25px 95px;
    overflow-x: hidden;
    position: relative;
}

.modal__close {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 15px;
	height: 15px;
	color:$dark;
	background: transparent;
	border: 0;

	&:before {
		content: "\2715";
	}

}

.modal__header {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 25px;
}

.modal__title {
	@include header_2;
    font-size:24px;
    margin-bottom: 0;
    text-align: center;
}

.modal__text {
    @include text;
}

.modal__content {
	width:370px;
}

.modal__btn {
	font-size: .875rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: .5rem;
	padding-bottom: .5rem;
	background-color: #e6e6e6;
	color: rgba(0, 0, 0, .8);
	border-radius: .25rem;
	border-style: none;
	border-width: 0;
	cursor: pointer;
	-webkit-appearance: button;
	text-transform: none;
	overflow: visible;
	line-height: 1.15;
	margin: 0;
	will-change: transform;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	transition: -webkit-transform .25s ease-out;
	transition: transform .25s ease-out;
	transition: transform .25s ease-out, -webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}

.modal__btn-primary {
	background-color: #00449e;
	color: #fff;
}


/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes mmfadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes mmslideIn {
	from {
		transform: translateY(15%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes mmslideOut {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-10%);
	}
}

.micromodal-slide {
	display: none;
}

.micromodal-slide.is-open {
	display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
	animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
	animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
	animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
	animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
	//will-change: transform;
}

.modal__video {
	.modal__container {
		max-width: 80vw;
		padding: 20px;
	}

	.modal__content {
		width: 100%;
	}

	video {
		width: 100%;
		height: auto;
	}
}


@media (max-width: 480px) {
	.modal__container {
		max-width: 100%;
		padding: 70px 20px;
	}

	.modal__header {
		justify-content: center;
	}

	.modal__title {
		font-size: 18px;
		line-height: 27px;
	}

	.modal__form {
		.button {
			width: 100%;
		}
	}

	.modal__content {
		width:100%;
	}

	.modal__video {
		.modal__container {
			max-width: 90%;
			padding: 10px;
		}
	}

}
