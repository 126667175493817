.banners {
  position: relative;
  z-index: 1;

    &__wrapper {
      display: flex;
      justify-content: space-between;

      @media(max-width: 768px) {
        flex-direction: column;
      }

    }

  &__banner {
    border-radius: 6px;
    width: 49%;

    @media(max-width: 768px) {
      width: 100%;

      &:first-child {
        margin-bottom:16px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
