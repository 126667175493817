.crumbs__btn {
    padding: 24px 0 32px 0;
    position: relative;
    z-index: 1;
}

.crumbs__btns {
    position: relative;
    display: flex;
    z-index: 3;

    @media(max-width: 768px) {
        flex-direction: column;
    }

    .button {
        &:first-child {
            margin-right: 20px;
            @media(max-width: 768px) {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }

        &--outline {
            border: 1px solid #fff;
            color: $whiteText;
            text-transform: uppercase;

            &:hover {
                background-color: $yellow;
                border: 1px solid $yellow;
                color: $dark;
            }
        }
    }
}

.crumbs__btn-item {
    display: inline-block;
    margin-right: 20px;
    opacity: 0.4;
    transition-property: opacity;
    transition-duration: 500ms;

    &:hover {
        opacity: 1;
    }
}

.crumbs__btn-link {
    padding: 12px 24px;
    background-color: #4E5AC7;
    border-radius: 24px;
    text-decoration: none;
    color: #fff;
}

.crumbs__btn-item--active {
    opacity: 1;
}

.crumbs__btn-ico {
    position: relative;
    fill: #fff;
    width: 26px;
    height: 26px;
    display: inline-block;

    svg {
        position: absolute;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
}

.crumbs__btn-title {
    display: inline-block;
    font-size: 11px;
    line-height: 150%;
    margin-left: 8px;
}

@media (max-width: 980px) {
    .crumbs__btn-item {
        margin-right: 8px;
    }

    .crumbs__btn {
        padding: 0px 0px 12px;
    }

    .crumbs__btn-title {
        display: none;
    }

}
