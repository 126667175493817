.form__group {
	margin-bottom: 20px;

	&:Last-of-type {
		margin-bottom: 0;
	}
}

.form__input {
	height: 50px;
	width: 100%;
	border-radius: 3px;
	background: #F7F8FA;

	font-family: $font;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: $gray;
	padding: 14px 20px;

    border: 1px solid transparent;

	&::placeholder {
		font-family: $font;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: 0em;
		text-align: left;
		color: $gray;
	}

	&:hover,
	&:focus,
	&:active {
		outline: none;
		border: 1px solid $gray;
	}

    &.error {
        color:$red;
        border-color:$red;
        background: lighten($red, 50%);

        &:hover,
        &:focus,
        &:active {
            border-color:$red;
        }
    }
}

textarea.form__input {
	min-height: 100px;
	outline: none;
	resize: none;
    border: 1px solid transparent;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $gray;
	}
}

.form__Label,
.form__label{
	margin-bottom: 15px;
    display: block;
}

.form__error-feedback {
    padding: 15px;
    margin-top: 15px;
    border-radius: 3px;
    background: #f5c6cb;
    color: #721c24;
    margin-bottom: 40px;
}

input[type="number"] {
	appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	display: none;
}

input[type="password"] {
    font-size: 20px;
}


.modal {
    .form__input {
        height: 25px;

        &::placeholder {
            font-size:12px;
        }
    }

    .form__Label,
    .form__label{
        font-size: 12px;
        margin-bottom: 5px;
    }

}
