.button {
    height: 60px;
    width: 270px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    text-transform: uppercase;
    transition: all $transitionTime ease-in-out;
    text-decoration: none;

    &:disabled {
        filter: grayscale(1);
        cursor:no-drop;
    }

    &--yellow {
        color: $dark;
        background-color: $yellow;

        &:hover,
        &:focus {
            background-color: #FBDF56;
        }
    }

	&--blue {
		color: #fff;
		background-color: $blue;

		&:hover,
		&:focus {
			background-color: #4E2EC1;
		}
	}

	&--outline {
		text-transform: none;
		color: $blue;
		background-color: transparent;
		border: 1px solid $blue;

		svg {
			margin-left: 20px;

			path {
				fill: $blue;
			}
		}

		&:hover,
		&:focus {
			color: $dark;
			border: 1px solid $dark;

			svg path {
				fill: $dark;
			}
		}
	}

	&--add {
		width: 40px;
		height: 40px;
		background-color: #CDD1DC;

		&:hover,
		&:focus {
			background-color: $blue;
		}
	}
}

.buttonIcon {
	background-color: #fff;
	height: 60px;
	width: 60px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;

	border: 2px solid #EBECF2;
	position: relative;

	svg path {
		fill: #DCDFE6;
	}

    i {
        color: #DCDFE6;
        font-size: 20px;
    }

	&:hover,
	&:focus {
		box-shadow: 0 7px 15px rgba(46, 88, 193, 0.1);
	}

	&--favorite {
		&:hover,
		&:focus {
			svg path {
				fill: #CDD1DC;;
			}

            i {
                color: #DCDFE6;
            }
		}
	}

	&--compare {
		&:hover,
		&:focus {
			svg path {
				fill: #CDD1DC;
			}
            i {
                color: #DCDFE6;
            }
		}
	}

	&--favorite-active {
		svg path {
			fill: $red;
		}

        i {
            color: $red;
        }
	}

	&--compare-active {
		svg path {
			fill: $blue;
		}

        i {
            color: $blue;
        }
	}

	&--borderless {
		border: none;
		background-color: transparent;
		padding: 0;
		width: 16px;
		height: 16px;

		&:hover,
		&:focus {
			box-shadow: none;
		}
	}
}


@media (max-width: 768px) {
	.button {
		height: 40px;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.05em;
	}
}
