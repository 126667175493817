$dark: #1a1a1a;
$gray: #484848;
$superGray: #666666;
$blue: #2e58c1;
$activeBlue: #183888;
$purple: #4e2ec1;
$yellow: #fce885;
$red: #c12e33;
$bgColor: #e5e5e5;
$bgGray: #f7f8fa;
$fontColor: #c4c4c4;
$borderColor: #e0e0e0;
$bc-btn: #FCE885;
$whiteText: #fff;

$font: "Montserrat";

$transitionTime: 250ms;

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
        url("../fonts/Montserrat-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Medium.woff2") format("woff2"),
        url("../fonts/Montserrat-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"),
        url("../fonts/Montserrat-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/Montserrat-Bold.woff") format("woff");
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("../fonts/roboto.woff2") format("woff2"),
        url("../fonts/roboto.woff") format("woff");
}

* {
    box-sizing: border-box;
}

.container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;

    @media (max-width: 1200px) {
        max-width: 960px;
    }

    @media (max-width: 980px) {
        max-width: 768px;
    }

    @media (max-width: 768px) {
        max-width: 720px;
        padding: 0 10px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
    }
}

.px-10 {
    padding: 0 10px;
}

html,
body {
    font-size: 14px;
    font-family: $font;
    color: $dark;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;

    @media (min-width: 1280px) {
        max-width: 1920px;
    }

    @media (max-width: 960px) {
        overflow-x: hidden;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        font-size: 12px;
        line-height: 1.5;
    }
}

button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    display: flex;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

img {
    max-width: 100%;
    height: auto;
}

input {
    box-shadow: none;
    border: none;
    box-sizing: border-box;
    font-family: $font;

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &::placeholder {
        font-family: $font;
    }
}

main {
    padding-top: 60px;
    margin-bottom: 40px;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
}

.swiper-container {
    overflow: hidden;
}

[hidden] {
    display: none !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mse2-ac-item {
    padding-top: 7px;
    padding-bottom: 7px;
}
